import { faStepBackward, faStepForward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addDays, isSameDay, subDays } from 'date-fns';
import { transparentize } from 'polished';
import React, { FC, Dispatch, SetStateAction } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

export const DateSelector: FC<{
	startDate: Date;
	endDate: Date;
	setStartDate: Dispatch<SetStateAction<Date>>;
	setEndDate: Dispatch<SetStateAction<Date>>;
}> = ({ startDate, endDate, setStartDate, setEndDate }) => {
	const now = new Date();

	return (
		<div>
			<span>Select range</span>
			<div style={{ height: '1rem' }} />
			<DatePickerWrapper>
				<button className="date-picker-button" onClick={() => setStartDate(cs => subDays(cs, 1))}>
					<FontAwesomeIcon icon={faStepBackward} />
				</button>
				<DatePicker
					selected={startDate}
					onChange={(date: Date) => setStartDate(date)}
					selectsStart
					startDate={startDate}
					dateFormat="yyyy-MM-dd"
					endDate={endDate}
					maxDate={now}
				/>
				<button
					className="date-picker-button"
					onClick={() => setStartDate(cs => addDays(cs, 1))}
					disabled={isSameDay(startDate, now)}
				>
					<FontAwesomeIcon icon={faStepForward} />
				</button>
				<button
					className="date-picker-button"
					onClick={() => setEndDate(cs => subDays(cs, 1))}
					disabled={isSameDay(startDate, endDate)}
				>
					<FontAwesomeIcon icon={faStepBackward} />
				</button>
				<DatePicker
					selected={endDate}
					onChange={(date: Date) => setEndDate(date)}
					selectsEnd
					startDate={startDate}
					endDate={endDate}
					dateFormat="yyyy-MM-dd"
					minDate={startDate}
					maxDate={now}
				/>
				<button
					className="date-picker-button"
					onClick={() => setEndDate(cs => addDays(cs, 1))}
					disabled={isSameDay(now, endDate)}
				>
					<FontAwesomeIcon icon={faStepForward} />
				</button>
			</DatePickerWrapper>
		</div>
	);
};

const DatePickerWrapper = styled.div`
	display: flex;
	margin-bottom: 1rem;

	@media only screen and (max-width: 500px) {
		flex-direction: column;
	}

	input {
		padding: 0.5rem;
		border: none;
		border-radius: 0.5rem;
		background-color: initial;
		color: ${({ theme }) => theme.colors.text.primary};
	}

	.react-datepicker__input-container {
		border-radius: 0.5rem;
	}

	.react-datepicker-wrapper {
		border: 1px solid ${({ theme }) => theme.colors.border};
		border-radius: 0;
		border-right-width: 0;

		&:first-child {
			border-top-left-radius: 0.5rem;
			border-bottom-left-radius: 0.5rem;
		}

		&:last-child {
			border-top-right-radius: 0.5rem;
			border-bottom-right-radius: 0.5rem;
			border-right-width: 1px;
		}

		@media only screen and (max-width: 500px) {
			border-right-width: 1px;
			padding: 0.25rem;
			border-bottom: 0;

			&:first-child {
				border-top-left-radius: 0.5rem;
				border-bottom-left-radius: 0.5rem;
			}

			&:last-child {
				border-top-right-radius: 0.5rem;
				border-bottom-right-radius: 0.5rem;
				border-right-width: 1px;
			}
		}
	}

	.date-picker-button {
		border: 1px solid ${({ theme }) => theme.colors.border};
		border-radius: 0;
		border-right-width: 0;
		padding: 0 1rem;

		&:disabled {
			color: ${({ theme }) => transparentize(0.5, theme.colors.text.primary)};
			cursor: not-allowed;
		}

		&:first-child {
			border-top-left-radius: 0.5rem;
			border-bottom-left-radius: 0.5rem;
		}

		&:last-child {
			border-top-right-radius: 0.5rem;
			border-bottom-right-radius: 0.5rem;
			border-right-width: 1px;
		}

		@media only screen and (max-width: 500px) {
			border-right-width: 1px;
			padding: 0.25rem;
			border-bottom-width: 0;

			&:first-child {
				border-top-left-radius: 0.5rem;
				border-top-right-radius: 0.5rem;
				border-bottom-left-radius: 0;
			}

			&:last-child {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0.5rem;
				border-bottom-left-radius: 0.5rem;
				border-right-width: 1px;
				border-bottom-width: 1px;
			}
		}
	}
`;
