import { faChartBar, faChartPie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartData, ChartOptions, ChartTooltipItem } from 'chart.js';
import React, { FC, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import styled from 'styled-components';
import { ButtonWrapper, OutlinedButton } from '../../../components/Buttons';
import { QuestionWithAnswer } from '../../../network/useSurvey';
import { stringToColor } from '../../../styles/stringToColor';
import { useTheme } from '../../../utils/useTheme';

export const ChartWrapper: FC<{ data: QuestionWithAnswer }> = ({ data }) => {
	const [chartType, setChartType] = useState<'BAR' | 'PIE'>('BAR');

	const alternatives: ChartDataType[] = data.alternatives
		.sort((a, b) => (b.value || 0) - (a.value || 0))
		.map(alternative => ({
			id: alternative.alternative,
			count: 0,
			backgroundColor: alternative.meta_data?.backgroundColor ?? stringToColor(alternative.alternative),
		}));

	const aggregatedAnswers = data.answers.reduce((acc, cur) => {
		const index = acc.findIndex((i: any) => i.id === cur.alternative);

		if (index === -1) {
			acc.push({
				id: cur.alternative,
				count: 1,
				backgroundColor: stringToColor(cur.alternative),
			});
		} else {
			acc[index] = {
				...acc[index],
				count: acc[index].count + 1,
			};
		}

		return acc;
	}, alternatives);

	return (
		<div>
			<QuestionWrapper>
				<h4>{data.question}</h4>
				<ButtonWrapper>
					<OutlinedButton size="s" active={chartType === 'PIE'} onClick={() => setChartType('PIE')}>
						<FontAwesomeIcon icon={faChartPie} />
						<span>Pie</span>
					</OutlinedButton>
					<OutlinedButton size="s" active={chartType === 'BAR'} onClick={() => setChartType('BAR')}>
						<FontAwesomeIcon icon={faChartBar} />
						<span>Bar</span>
					</OutlinedButton>
				</ButtonWrapper>
			</QuestionWrapper>
			{chartType === 'BAR' ? <BarChart data={aggregatedAnswers} /> : <PieChart data={aggregatedAnswers} />}
		</div>
	);
};

const QuestionWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 16px;
	margin-bottom: 8px;
`;

type ChartDataType = {
	id: string;
	count: number;
	backgroundColor: string;
};

export const BarChart: FC<{ data: ChartDataType[] }> = ({ data }) => {
	const { theme } = useTheme();
	const total = data.reduce((acc, cur) => acc + cur.count, 0);

	const options = {
		maintainAspectRatio: false,
		legend: {
			display: true,
			labels: {
				fontColor: theme.colors.text.primary,
			},
		},
		tooltips: {
			callbacks: {
				label: (tooltipItem: ChartTooltipItem, chartData: ChartData) => {
					const { datasets } = chartData;
					if (datasets && datasets.length > 0) {
						const value = tooltipItem.value || '0';
						const valueAsNumber = (typeof Number(value) === 'number' && parseInt(value, 10)) || 0;

						return `${valueAsNumber} (${Math.round((valueAsNumber / total) * 100)}%)`;
					}
				},
			},
		},
		scales: {
			xAxes: [
				{
					gridLines: {
						offsetGridLines: true,
					},
					ticks: {
						fontColor: theme.colors.text.primary,
					},
				},
			],
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
						fontColor: theme.colors.text.primary,
					},
				},
			],
		},
	};

	const barData = {
		labels: [''],
		datasets: data.map(alternative => ({
			label: `${alternative.id} (${Math.round((alternative.count / total) * 100)}%)`,
			data: [alternative.count],
			backgroundColor: [alternative.backgroundColor],
		})),
	};

	return <Bar width={500} height={300} data={barData} options={options as ChartOptions} />;
};

export const PieChart: FC<{ data: ChartDataType[] }> = ({ data }) => {
	const { theme } = useTheme();
	const total = data.reduce((acc, cur) => acc + cur.count, 0);

	const options: ChartOptions = {
		maintainAspectRatio: false,
		legend: {
			display: true,
			labels: {
				fontColor: theme.colors.text.primary,
			},
		},
		tooltips: {
			callbacks: {
				label: (tooltipItem: ChartTooltipItem, chartData: ChartData) => {
					const { datasets } = chartData;
					if (datasets && datasets.length > 0) {
						const datasetData = datasets[0].data;

						if (Array.isArray(datasetData) && datasetData.length > 0) {
							const value = datasetData[tooltipItem.index ?? 0];
							const valueAsNumber = typeof value === 'number' ? value : 0;

							return `${valueAsNumber} (${Math.round((valueAsNumber / total) * 100)}%)`;
						}
					}

					return '';
				},
			},
		},
	};

	const pieData = {
		labels: data.map(d => `${d.id} (${Math.round((d.count / total) * 100)}%)`),
		datasets: [
			{
				data: data.map(({ count }) => count),
				backgroundColor: data.map(({ backgroundColor }) => backgroundColor),
				borderWidth: 0,
			},
		],
	};

	return <Pie width={500} height={300} data={pieData} options={options} />;
};
