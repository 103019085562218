import { FC } from 'react';
import styled from 'styled-components';
import { Loader } from '../../components/Loader';
import { useGetSurvey } from '../../network/useSurvey';
import { Card } from '../surveys/components/Card';

export const FavoriteSurvey: FC<{ surveyId: number }> = ({ surveyId }) => {
	const { isLoading, data } = useGetSurvey(surveyId);

	if (isLoading || !data) {
		return (
			<LoadingSurveyCard>
				<Loader />
			</LoadingSurveyCard>
		);
	}

	return <Card survey={data} />;
};

const LoadingSurveyCard = styled.div`
	width: 250px;
	height: 200px;
	border-radius: 1rem;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 200ms ease-in-out;
	box-shadow: ${({ theme }) => theme.shadow.s};

	&:hover {
		box-shadow: ${({ theme }) => theme.shadow.l};
	}
`;
