import { useState } from 'react';
import { createContainer } from 'unstated-next';

const useEditSurvey = () => {
	const [isSaving, setIsSaving] = useState<boolean>(false);

	return {
		isSaving,
		setIsSaving,
	};
};

const EditSurveyContainer = createContainer(useEditSurvey);
export const EditSurveyProvider = EditSurveyContainer.Provider;
export const useEditSurveyStore = () => EditSurveyContainer.useContainer();
