import { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';
import { InteractiveBackground } from './components/InteractiveBackground';
import { Loader } from './components/Loader';
import { SignIn } from './pages/SignIn';
import { Router } from './router/Router';
import { AuthProvider, useAuthStore } from './store/auth';
import { UserSettingsProvider } from './store/userSettings';
import { GlobalStyle } from './styles/style';
import { useTheme } from './utils/useTheme';

export const App: FC = () => {
	return (
		<>
			<AuthProvider>
				<UserSettingsProvider>
					<InnerApp />
				</UserSettingsProvider>
			</AuthProvider>
		</>
	);
};

const queryClient = new QueryClient();

const InnerApp: FC = () => {
	const { state } = useAuthStore();
	const { theme } = useTheme();

	switch (state.type) {
		case 'loading':
			return (
				<ThemeProvider theme={theme}>
					<InteractiveBackground style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Loader />
					</InteractiveBackground>
				</ThemeProvider>
			);

		case 'signedOut':
		case 'error':
			return (
				<ThemeProvider theme={theme}>
					<SignIn />
				</ThemeProvider>
			);

		case 'signedIn':
			return (
				<QueryClientProvider client={queryClient}>
					<ThemeProvider theme={theme}>
						<GlobalStyle />
						<Router />
					</ThemeProvider>
				</QueryClientProvider>
			);
	}
};
