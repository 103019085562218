import React, { FC } from 'react';
import styled from 'styled-components';

type NumberOfAnswersProps = {
	timesOffered: number;
	numberOfUniqueAnswers: number;
};

export const NumberOfAnswers: FC<NumberOfAnswersProps> = ({ timesOffered, numberOfUniqueAnswers }) => {
	const percentageOfTotal = Math.round((numberOfUniqueAnswers / timesOffered) * 100);
	return (
		<NumberOfAnswersWrapper percentageOfTotal={percentageOfTotal}>
			<span>
				Responses of survey views (<span title="Number that answered the survey">{numberOfUniqueAnswers}</span> of{' '}
				<span title="Number that got the survey">{timesOffered}</span>)
			</span>
			<div style={{ height: '1rem' }} />
			<div className="progress">
				<span />
			</div>
		</NumberOfAnswersWrapper>
	);
};

const NumberOfAnswersWrapper = styled.div<{ percentageOfTotal: number }>`
	display: flex;
	flex: 1;
	flex-direction: column;

	.progress {
		border: 1px solid ${({ theme }) => theme.colors.border};
		border-radius: 0.5rem;
		min-height: 36px;
		max-height: 36px;
		display: flex;
		flex: 1;
		background-color: ${({ theme }) => theme.colors.background.tertiary};
		position: relative;

		span {
			border-radius: 7px 0 0 7px;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: ${({ percentageOfTotal }) => (percentageOfTotal > 100 ? 0 : percentageOfTotal)}%;
			background-color: ${({ theme }) => theme.colors.green.secondary};
			transition: width ease-in-out 600ms;

			&::after {
				content: '${({ percentageOfTotal }) => `${percentageOfTotal > 100 ? '🦄' : percentageOfTotal}%`}';
				font-size: 14px;
				width: 40px;
				text-align: center;
				position: absolute;
				right: -20px;
				top: -18px;
			}
		}
	}
`;
