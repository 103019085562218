import { FC, useState } from 'react';
import ReactAutosuggest, { InputProps } from 'react-autosuggest';
import styled from 'styled-components';

type Suggestion = {
	name: string;
};

export const Autosuggest: FC<{ inputProps: InputProps<Suggestion>; suggestions: Suggestion[] }> = ({
	inputProps,
	suggestions: suggestionsProp,
}) => {
	const { suggestions, onSuggestionsFetchRequested, onSuggestionsClearRequested } = useAutosuggest(suggestionsProp);

	return (
		<StyledAutosuggest>
			<ReactAutosuggest
				suggestions={suggestions}
				onSuggestionsFetchRequested={onSuggestionsFetchRequested}
				onSuggestionsClearRequested={onSuggestionsClearRequested}
				getSuggestionValue={getSuggestionValue}
				renderSuggestion={renderSuggestion}
				inputProps={inputProps}
			/>
		</StyledAutosuggest>
	);
};

const StyledAutosuggest = styled.div`
	margin: 16px 0;

	.react-autosuggest__container {
		position: relative;
	}

	.react-autosuggest__input {
		width: 240px;
		height: 30px;
		padding: 10px 20px;
		font-weight: 300;
		font-size: 16px;
		border: 1px solid ${props => props.theme.colors.border};
		border-radius: 4px;
		-webkit-appearance: none;
	}

	.react-autosuggest__input--focused {
		outline: none;
	}

	.react-autosuggest__input::-ms-clear {
		display: none;
	}

	.react-autosuggest__input--open {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.react-autosuggest__suggestions-container {
		display: none;
	}

	.react-autosuggest__suggestions-container--open {
		display: block;
		position: absolute;
		top: 51px;
		width: 280px;
		border: 1px solid #aaa;
		background-color: #fff;
		font-family: 'Open Sans', sans-serif;
		font-weight: 300;
		font-size: 16px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		z-index: 2;
	}

	.react-autosuggest__suggestions-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.react-autosuggest__suggestion {
		cursor: pointer;
		padding: 10px 20px;
	}

	.react-autosuggest__suggestion--highlighted {
		background-color: #ddd;
	}

	.react-autosuggest__section-container {
		border-top: 1px dashed #ccc;
	}

	.react-autosuggest__section-container--first {
		border-top: 0;
	}

	.react-autosuggest__section-title {
		padding: 10px 0 0 10px;
		font-size: 12px;
		color: #777;
	}
`;

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion: Suggestion) => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion: Suggestion) => <div>{suggestion.name}</div>;

export const useAutosuggest = (
	initSuggestions: Suggestion[]
): {
	suggestions: Suggestion[];
	onSuggestionsFetchRequested: ({ value }: { value: any }) => void;
	onSuggestionsClearRequested: () => void;
} => {
	const [suggestions, setSuggestions] = useState<Suggestion[]>(initSuggestions);

	// Teach Autosuggest how to calculate suggestions for any given input value.
	const getSuggestions = (value: string) => {
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;

		return inputLength === 0
			? []
			: suggestions.filter(sug => sug.name.toLowerCase().slice(0, inputLength) === inputValue);
	};

	// Autosuggest will call this function every time you need to update suggestions.
	// You already implemented this logic above, so just use it.
	const onSuggestionsFetchRequested = ({ value }: { value: any }) => {
		setSuggestions(getSuggestions(value));
	};

	// Autosuggest will call this function every time you need to clear suggestions.
	const onSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	return {
		suggestions: suggestions,
		onSuggestionsFetchRequested: onSuggestionsFetchRequested,
		onSuggestionsClearRequested: onSuggestionsClearRequested,
	};
};
