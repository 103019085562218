import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

// https://tobiasahlin.com/spinkit/

const animation = keyframes`
  0% { 
    transform: scale(0);
  } 100% {
    transform: scale(1.0);
    opacity: 0;
  }
`;

const unicornAnimation = keyframes`
  0% { 
    font-size: 10px;
  } 50% {
    font-size: 80px;
  } 100% {
    font-size: 10px;
  }
`;

const StyledLoader = styled.div<{ black?: boolean }>`
	width: 80px;
	height: 80px;
	margin: 100px auto;
	background-color: ${props => (props.black ? props.theme.colors.text.black : props.theme.colors.text.white)};
	border-radius: 100%;
	animation: ${animation} 1s infinite ease-in-out;
`;

const StyledUnicorn = styled.div`
	width: 80px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 80px;
	margin: 100px auto;
	animation: ${unicornAnimation} 2s infinite ease-in-out;
`;

export const Loader: FC<{ black?: boolean }> = ({ black }) => {
	const unicorn = Math.floor(Math.random() * 5) === 1;

	if (unicorn) {
		return <StyledUnicorn>🦄</StyledUnicorn>;
	}

	return <StyledLoader black={black} />;
};
