import styled from 'styled-components';

export const Container = styled.div`
	width: ${({ theme }) => (theme.system.width === 'normal' ? '1200px' : 'inherit')};
	border-radius: 5px;
	background-color: ${({ theme }) => theme.colors.background.secondary};
	padding: 16px;
	margin: ${({ theme }) => {
		const { width, sideMenuExpanded } = theme.system;

		const marginTop = '72px';
		const marginRight = width === 'normal' ? 'auto' : '16px';
		const marginBottom = '72px';
		const marginLeft = width === 'normal' ? 'auto' : sideMenuExpanded ? '176px' : '76px';

		return marginTop + ' ' + marginRight + ' ' + marginBottom + ' ' + marginLeft;
	}};
	box-shadow: ${({ theme }) => theme.shadow.m};

	@media only screen and (max-width: 1232px) {
		margin: 72px 16px;
	}
`;
