import {
	faAlignJustify,
	faCheckCircle,
	faCheckSquare,
	faChevronDown,
	faFont,
	faKeyboard,
	faListOl,
	faListUl,
	faMountain,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { darken, lighten, readableColor } from 'polished';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Button, IconButton, LinkButton } from '../../../components/Buttons';
import { Row } from '../../../components/Flex';
import { InfoBox } from '../../../components/InfoBox';
import { ListWrapper } from '../../../components/Lists';
import { Alternative as AlternativeType, Question as QuestionType } from '../../../network/useSurvey';
import { useTheme } from '../../../utils/useTheme';

export const Questions: FC<{ questions: QuestionType[]; surveyId: string }> = ({ questions, surveyId }) => {
	return (
		<>
			<Row justify="space-between" align="center" style={{ marginBottom: 24 }}>
				<h2 style={{ margin: 0 }}>Questions</h2>
				<LinkButton to={`/surveys/edit/${surveyId}`}>Edit survey</LinkButton>
			</Row>
			<ListWrapper>
				{questions.map(question => (
					<Question key={question.id} question={question} />
				))}
			</ListWrapper>
		</>
	);
};

const Question: FC<{ question: QuestionType }> = ({ question }) => {
	const [expanded, setExpanded] = useState<boolean>(false);
	return (
		<>
			<StyledQuestion expanded={expanded}>
				<h4>{question.question}</h4>
				<IconsWrapper>
					{question.type === 'CHECKBOX' && (
						<InfoBox title="Checkbox">
							<FontAwesomeIcon icon={faCheckSquare} />
						</InfoBox>
					)}
					{question.type === 'RADIO' && (
						<InfoBox title="Radio">
							<FontAwesomeIcon icon={faCheckCircle} />
						</InfoBox>
					)}
					{question.type === 'FREE_TEXT' && (
						<InfoBox title="Free text">
							<FontAwesomeIcon icon={faKeyboard} />
						</InfoBox>
					)}
					{question.type === 'LIKERT_SCALE' && (
						<InfoBox title="Likert scale">
							<FontAwesomeIcon icon={faListUl} />
						</InfoBox>
					)}

					<InfoBox title="Number of alternatives">
						<FontAwesomeIcon icon={faListOl} />
						<span>{question.alternatives.length}</span>
					</InfoBox>
					<IconButton
						onClick={() => setExpanded(cs => !cs)}
						style={{
							transition: 'transform 200ms ease-in-out',
							transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
						}}
					>
						<FontAwesomeIcon icon={faChevronDown} />
					</IconButton>
				</IconsWrapper>
			</StyledQuestion>
			{expanded && (
				<ExpandedContent>
					{question.alternatives.map((alternative, index) => (
						<Alternative key={index} alternative={alternative} />
					))}
				</ExpandedContent>
			)}
		</>
	);
};
const StyledQuestion = styled.div<{ expanded: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.5rem 1rem;
	border-bottom: ${({ theme, expanded }) => (expanded ? 'none' : `1px solid ${theme.colors.border}`)};
`;

const ExpandedContent = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.border};
	padding: 0.5rem 0 1rem 0;
`;

const Alternative: FC<{ alternative: AlternativeType }> = ({ alternative }) => {
	const { theme } = useTheme();

	return (
		<StyledAlternative>
			<div>{alternative.alternative}</div>
			<IconsWrapper>
				{alternative.meta_data.textColor && (
					<InfoBox backgroundColor={alternative.meta_data.textColor} title="Alternative text color">
						<FontAwesomeIcon
							icon={faFont}
							color={readableColor(
								lighten(0.2, alternative.meta_data.textColor),
								theme.colors.text.black,
								theme.colors.text.white
							)}
						/>
					</InfoBox>
				)}
				{alternative.meta_data.backgroundColor && (
					<InfoBox backgroundColor={alternative.meta_data.backgroundColor} title="Alternative background color">
						<FontAwesomeIcon
							icon={faMountain}
							color={readableColor(
								lighten(0.2, alternative.meta_data.backgroundColor),
								theme.colors.text.black,
								theme.colors.text.white
							)}
						/>
					</InfoBox>
				)}
				{alternative.meta_data.multiline && (
					<InfoBox title="Multiline">
						<FontAwesomeIcon icon={faAlignJustify} />
					</InfoBox>
				)}
			</IconsWrapper>
		</StyledAlternative>
	);
};
const StyledAlternative = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;

	&:hover {
		background-color: ${({ theme }) => darken(0.04, theme.colors.background.secondary)};
	}
`;
const IconsWrapper = styled.div`
	display: flex;

	div,
	button {
		margin-left: 16px;
	}
`;
