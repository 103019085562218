import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faPencilAlt, faPlus, faSpider } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { FC, useState } from 'react';
import slugify from 'slugify';
import { ErrorAlert } from '../../../components/Alerts';
import { Button, OutlinedButton } from '../../../components/Buttons';
import { Container } from '../../../components/Container';
import { Column, Row } from '../../../components/Flex';
import { InfoBox } from '../../../components/InfoBox';
import { Input } from '../../../components/inputs/Input';
import { Textarea } from '../../../components/inputs/Textarea';
import { Loader } from '../../../components/Loader';
import { Question, SurveyWithQuestions, useCreateSurvey, useGetSurvey } from '../../../network/useSurvey';
import { useTheme } from '../../../utils/useTheme';
import { EditQuestion } from './EditQuestion';
import { EditSurveyProvider, useEditSurveyStore } from './editSurveyContext';

export const CreateSurvey: FC<RouteComponentProps<{ surveyId?: number }>> = ({ surveyId }) => {
	return (
		<EditSurveyProvider>
			<Container style={{ padding: '16px 0' }}>
				{surveyId ? <EditSurvey surveyId={surveyId} /> : <NewSurvey />}
			</Container>
		</EditSurveyProvider>
	);
};

const NewSurvey: FC = () => {
	const { createSurvey } = useCreateSurvey();
	const navigate = useNavigate();
	const [name, setName] = useState<string>('');
	const [displayName, setDisplayName] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [error, setError] = useState<string | undefined>(undefined);

	const handleCreateSurveyClick = async () => {
		setError(undefined);
		if (name) {
			const response = await createSurvey({
				name,
				displayName,
				description,
			});

			if (response.success) {
				navigate(`/surveys/edit/${response.data.id}`);
			} else {
				setError(response.error);
			}
		}
	};

	return (
		<div style={{ padding: '0 16px' }}>
			<h1>Create</h1>
			{error}
			<Input
				label="Survey name"
				value={displayName}
				onChange={text => {
					setDisplayName(text);
					setName(
						slugify(text, {
							replacement: '_',
							lower: true,
						})
					);
				}}
			/>
			<Input label="Slug" value={name} onChange={text => setName(text)} />
			<Textarea value={description} onChange={text => setDescription(text)} label="Description" />
			<Button onClick={handleCreateSurveyClick}>Create</Button>
			{error && <ErrorAlert style={{ marginTop: 16 }}>{error}</ErrorAlert>}
		</div>
	);
};

const EditSurvey: FC<{ surveyId: number }> = ({ surveyId }) => {
	const { data, isFetching, refetch } = useGetSurvey(surveyId);

	if (isFetching) {
		return <Loader />;
	}

	if (data) {
		return <EditSurveyWithData data={data} refetch={refetch} />;
	}

	return <ErrorAlert style={{ margin: 16 }}>Something went wrong</ErrorAlert>;
};

const EditSurveyWithData: FC<{ data: SurveyWithQuestions; refetch: () => void }> = ({ data, refetch }) => {
	const { isSaving, setIsSaving } = useEditSurveyStore();
	const { addQuestion, editSurvey } = useCreateSurvey();
	const { theme } = useTheme();
	const [survey, setSurvey] = useState<SurveyWithQuestions>(data);
	const [questions, setQuestions] = useState<Question[]>(data.questions);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | undefined>(undefined);
	const [editDisplayName, setEditDisplayName] = useState<boolean>(false);

	const handleAddQuestionClick = async () => {
		setError(undefined);
		setIsLoading(true);
		const response = await addQuestion(data.id, {
			question: '',
			type: 'FREE_TEXT',
			meta_data: {
				sort_order: questions.length + 1,
			},
		});

		if (response.success) {
			setQuestions(cs => [
				...cs,
				{
					...response.data,
					alternatives: [],
				},
			]);
		} else {
			setError(response.error);
		}

		setIsLoading(false);
	};

	const handleEditSurvey = (key: 'active' | 'description' | 'display_name', value: string | boolean) => {
		setSurvey(cs => ({
			...cs,
			[key]: value,
		}));
	};

	const handleUpdateSurvey = async (body?: Record<string, unknown>) => {
		setIsSaving(true);
		await editSurvey(survey.id, {
			active: survey.active,
			display_name: survey.display_name,
			description: survey.description,
			meta_data: survey.meta_data,
			...body,
		});
		setIsSaving(false);
	};

	return (
		<>
			<Row align="center" justify="space-between" style={{ margin: 16 }}>
				<Row flex={1}>
					{editDisplayName ? (
						<Input
							value={survey.display_name}
							label="Survey title"
							size="l"
							labelOnlyForScreenReaders
							onChange={text => handleEditSurvey('display_name', text)}
							style={{ margin: 0 }}
						/>
					) : (
						<h1 style={{ margin: 0 }}>{data.display_name}</h1>
					)}
					<div style={{ width: 16 }} />
					<OutlinedButton size="s" active={editDisplayName} onClick={() => setEditDisplayName(cs => !cs)}>
						<FontAwesomeIcon
							icon={faPencilAlt}
							style={{ margin: 0 }}
							color={editDisplayName ? theme.colors.text.white : theme.colors.porter.primary}
						/>
					</OutlinedButton>
				</Row>
				<InfoBox style={{ padding: 6, marginRight: 16 }} title={isSaving ? 'Saving' : 'Saved'}>
					<FontAwesomeIcon
						icon={isSaving ? faSpider : faCheckCircle}
						style={{ width: 32, height: 32 }}
						color={theme.colors.porter.primary}
						spin={isSaving}
					/>
				</InfoBox>
				<OutlinedButton
					active={!survey.active}
					onClick={() => {
						const newValue = !survey.active;

						handleEditSurvey('active', newValue);
						handleUpdateSurvey({
							active: newValue,
						});
					}}
				>
					{survey.active ? 'Deactivate' : 'Activate'} survey
				</OutlinedButton>
			</Row>
			<Column style={{ padding: 16, paddingTop: 0 }}>
				<h3>{data.name}</h3>
				<Textarea
					style={{ marginTop: 16 }}
					value={survey.description}
					onBlur={handleUpdateSurvey}
					onChange={text => handleEditSurvey('description', text)}
					label="Description"
					labelOnlyForScreenReaders
					placeholder="Description (Optional)"
				/>
			</Column>
			<h2 style={{ padding: '0 16px' }}>Questions</h2>
			{questions.map(question => (
				<EditQuestion key={question.id} surveyId={data.id} question={question} refetch={refetch} />
			))}
			<Column align="flex-start" style={{ padding: 16 }}>
				<Button onClick={handleAddQuestionClick}>
					<FontAwesomeIcon icon={faPlus} spin={isLoading} />
					Add question
				</Button>
				{error && <ErrorAlert style={{ marginTop: 16 }}>{error}</ErrorAlert>}
			</Column>
		</>
	);
};
