import { RouteComponentProps, Router as ReachRouter } from '@reach/router';
import React, { FC } from 'react';
import { Home } from '../pages/home/Home';
import { CreateSurvey } from '../pages/surveys/create/CreateSurvey';
import { Survey } from '../pages/surveys/Survey';
import { Surveys } from '../pages/surveys/Surveys';
import { Volumes } from '../pages/volumes/Volumes';
import { Navigator } from './Navigator';

export const Router: FC = () => {
	return (
		<ReachRouter>
			<InnerRouter path="/">
				<Home path="/" />
				<Surveys path="/surveys" />
				<CreateSurvey path="/surveys/new" />
				<CreateSurvey path="/surveys/edit/:surveyId" />
				<Survey path="/surveys/:surveyId" />
				<Volumes path="/volumes" />
			</InnerRouter>
		</ReachRouter>
	);
};

const InnerRouter: FC<RouteComponentProps> = ({ children }) => {
	return (
		<>
			<Navigator />
			{children}
		</>
	);
};
