import { RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../components/Container';
import { Header } from '../../components/Header';
import { useFavorites } from '../../utils/useFavorites';
import { FavoriteSurvey } from './FavoriteSurvey';

export const Home: FC<RouteComponentProps> = () => {
	const { favoriteSurveys } = useFavorites();

	return (
		<Container>
			<Header>
				<h1>Favorites</h1>
			</Header>
			<Content>
				{favoriteSurveys.length === 0
					? 'Your favorite content will appear here'
					: favoriteSurveys.map(surveyId => <FavoriteSurvey key={surveyId} surveyId={surveyId} />)}
			</Content>
		</Container>
	);
};

const Content = styled.div`
	display: grid;
	gap: 24px;
	grid-template-columns: repeat(auto-fit, 250px);
`;
