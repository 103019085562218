import { transparentize } from 'polished';
import { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import checkImg from '../../assets/check-img.svg';

export const Checkbox: FC<{
	title: string;
	checked: boolean;
	onChange: () => void;
	style?: CSSProperties;
}> = ({ title, checked, onChange, style }) => {
	return (
		<Container style={style}>
			{title}
			<input type="checkbox" checked={checked} onChange={onChange} />
			<span />
		</Container>
	);
};

const Container = styled.label`
	display: block;
	position: relative;
	padding-left: 24px;
	margin-bottom: 12px;
	cursor: pointer;
	user-select: none;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}

	input ~ span:after {
		display: none;
	}

	&:hover input ~ span {
		background-color: ${({ theme }) => transparentize(0.8, theme.colors.porter.primary)};
	}

	input:hover ~ span:after {
		display: block;
		opacity: 0.4;
	}

	input:checked ~ span {
		background-color: ${({ theme }) => theme.colors.porter.primary};
	}

	input:checked ~ span:after {
		display: block;
	}

	span {
		position: absolute;
		top: 2px;
		left: 0;
		height: 1rem;
		width: 1rem;
		background-color: ${({ theme }) => theme.colors.background.secondary};
		border: 1px solid ${({ theme }) => theme.colors.porter.primary};
		border-radius: 2px;

		&:after {
			content: '';
			position: absolute;
			top: 2px;
			left: 2px;
			width: 10px;
			height: 10px;
			border-radius: 2px;
			background-image: url(${checkImg});
			background-repeat: no-repeat;
		}
	}
`;
