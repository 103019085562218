import { faFont, faTrash, faMountain, faAlignJustify } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { readableColor, lighten, darken } from 'polished';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { DangerOutlinedButton } from '../../../components/Buttons';
import { Row } from '../../../components/Flex';
import { InfoBox } from '../../../components/InfoBox';
import { Checkbox } from '../../../components/inputs/Checkbox';
import { Input } from '../../../components/inputs/Input';
import { Select } from '../../../components/inputs/Select';
import { Alternative, QuestionType, useCreateSurvey } from '../../../network/useSurvey';
import { useTheme } from '../../../utils/useTheme';
import { useEditSurveyStore } from './editSurveyContext';

export const EditAlternative: FC<{
	surveyId: number;
	questionId: number;
	alternative: Alternative;
	type: QuestionType;
	refetch: () => void;
}> = ({ surveyId, questionId, alternative: initialAlternative, type, refetch }) => {
	const { theme } = useTheme();
	const { deleteAlternative, editAlternative } = useCreateSurvey();
	const { setIsSaving } = useEditSurveyStore();
	const [alternative, setAlternative] = useState<Alternative>(initialAlternative);

	const handleEditAlternative = (key: keyof typeof initialAlternative, value: string | number) => {
		setAlternative(cs => ({
			...cs,
			[key]: value,
		}));
	};

	const handleEditAlternativeMetaData = (key: keyof typeof initialAlternative.meta_data, value: string | boolean) => {
		setAlternative(cs => ({
			...cs,
			meta_data: {
				...cs.meta_data,
				[key]: value,
			},
		}));
	};

	const handleDeleteAlternative = async () => {
		setIsSaving(true);
		if (window.confirm(`Are you sure you want to delete ${alternative.value}?`)) {
			await deleteAlternative(surveyId, questionId, alternative.id);
			refetch();
		}
		setIsSaving(false);
	};

	const handleUpdateAlternative = async (body?: Record<string, unknown>) => {
		setIsSaving(true);
		await editAlternative(surveyId, questionId, alternative.id, {
			alternative: alternative.alternative,
			value: alternative.value,
			meta_data: alternative.meta_data,
			...body,
		});
		setIsSaving(false);
	};

	return (
		<StyledAlternative>
			<Row align="flex-end" style={{ marginBottom: 8 }}>
				<Input
					value={alternative.id}
					onChange={() => undefined}
					label="ID"
					placeholder="ID"
					disabled
					style={{ width: 50, marginRight: 8, marginBottom: 0 }}
				/>
				<Input
					value={alternative.alternative}
					onChange={text => handleEditAlternative('alternative', text)}
					onBlur={() => handleUpdateAlternative()}
					label="Alternative"
					placeholder="Alternative"
					style={{ marginRight: 16, marginBottom: 0 }}
				/>
				{type !== 'FREE_TEXT' && (
					<Input
						value={alternative.value}
						onChange={text => handleEditAlternative('value', parseInt(text, 10))}
						onBlur={() => handleUpdateAlternative()}
						type="number"
						label="Value"
						placeholder="Value"
						style={{ marginRight: 16, marginBottom: 0, width: '7ch' }}
					/>
				)}
				<DangerOutlinedButton onClick={handleDeleteAlternative}>
					<FontAwesomeIcon icon={faTrash} />
					Delete
				</DangerOutlinedButton>
			</Row>
			<IconsWrapper>
				<Checkbox
					title="Can comment"
					checked={Boolean(alternative.meta_data?.canComment)}
					style={{ marginBottom: 0, marginRight: 16 }}
					onChange={() => {
						const newValue = !Boolean(alternative.meta_data?.canComment);

						handleEditAlternativeMetaData('canComment', newValue);
						handleUpdateAlternative({
							meta_data: {
								...alternative.meta_data,
								required: newValue,
							},
						});
					}}
				/>
				<Select
					value={alternative.meta_data?.emoji || 'Select emoji'}
					label="Select emoji"
					size="s"
					onChange={value => {
						handleEditAlternativeMetaData('emoji', value);
						handleUpdateAlternative({
							meta_data: {
								...alternative.meta_data,
								emoji: value || null,
							},
						});
					}}
					style={{ marginBlock: 0 }}
					options={[
						{ value: '', name: 'Select emoji' },
						{ value: ':smile_face:', name: 'Smile face' },
						{ value: ':happy_face:', name: 'Happy face' },
						{ value: ':neutral_face:', name: 'Neutral face' },
					]}
					labelOnlyForScreenReaders
				/>
				<InfoBox backgroundColor={safeHexColor(alternative.meta_data?.textColor)} title="Alternative text color">
					<FontAwesomeIcon
						icon={faFont}
						color={readableColor(
							lighten(0.2, safeHexColor(alternative.meta_data?.textColor)),
							theme.colors.text.black,
							theme.colors.text.white
						)}
						style={{ marginRight: 8 }}
					/>
					<ColorInput
						type="text"
						value={alternative.meta_data?.textColor}
						color={alternative.meta_data?.textColor}
						placeholder="#abc123"
						onChange={event => handleEditAlternativeMetaData('textColor', event.target.value)}
						onBlur={() =>
							handleUpdateAlternative({
								metaData: {
									...alternative.meta_data,
									textColor: alternative.meta_data?.textColor ?? null,
								},
							})
						}
					/>
				</InfoBox>
				<InfoBox
					backgroundColor={safeHexColor(alternative.meta_data?.backgroundColor)}
					title="Alternative background color"
				>
					<FontAwesomeIcon
						icon={faMountain}
						color={readableColor(
							lighten(0.2, safeHexColor(alternative.meta_data?.backgroundColor)),
							theme.colors.text.black,
							theme.colors.text.white
						)}
						style={{ marginRight: 8 }}
					/>
					<ColorInput
						type="text"
						value={alternative.meta_data?.backgroundColor}
						color={alternative.meta_data?.backgroundColor}
						placeholder="#abc123"
						onChange={event => handleEditAlternativeMetaData('backgroundColor', event.target.value)}
						onBlur={() =>
							handleUpdateAlternative({
								metaData: {
									...alternative.meta_data,
									textColor: alternative.meta_data?.backgroundColor ?? null,
								},
							})
						}
					/>
				</InfoBox>
				{alternative.meta_data?.multiline && (
					<InfoBox title="Multiline">
						<FontAwesomeIcon icon={faAlignJustify} />
					</InfoBox>
				)}
			</IconsWrapper>
		</StyledAlternative>
	);
};
function safeHexColor(color?: string) {
	if (color && /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(color)) {
		return color;
	}

	return '#000000';
}
const StyledAlternative = styled.div`
	margin-left: 24px;
	margin-bottom: 16px;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	&:hover {
		background-color: ${({ theme }) => darken(0.04, theme.colors.background.secondary)};
	}
`;
const IconsWrapper = styled.div`
	display: flex;
	align-items: center;

	div,
	button {
		margin-left: 16px;
	}
`;
const ColorInput = styled.input<{ color?: string }>`
	background-color: ${props => safeHexColor(props.color || '#ffffff')};
	color: ${props =>
		readableColor(
			lighten(0.2, safeHexColor(props.color || '#ffffff')),
			props.theme.colors.text.black,
			props.theme.colors.text.white
		)};
	border: none;
	width: 8ch;
`;
