import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { usePorterApi } from './../utils/useApi';
import { format } from 'date-fns';

type Count = {
	accountId: number;
	accountName: string;
	count: number;
	date: string;
	geoZone: string;
};

export type DateCount = {
	counts: Count[];
	date: string;
};

type RegionCount = {
	orderDateByAccountCount: DateCount[];
	region: string;
};

type DeliveriesByAccount = {
	accountNames: string[];
	dateCounts: DateCount[];
	regionCounts: RegionCount[];
};

export const useGetDeliveriesByAccount = (start: Date, end: Date) => {
	const porterApi = usePorterApi();

	return useQuery<DeliveriesByAccount, AxiosError>(
		'deliveriesByAccountFetch',
		() =>
			porterApi
				.get('/stats/deliveries-by-account', {
					params: {
						start: format(start, 'yyyy-MM-dd'),
						end: format(end, 'yyyy-MM-dd'),
					},
				})
				.then(resp => resp.data),
		{
			staleTime: Infinity,
		}
	);
};
