import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteComponentProps } from '@reach/router';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { ErrorAlert } from '../../components/Alerts';
import { LinkButton, OutlinedButton } from '../../components/Buttons';
import { Container } from '../../components/Container';
import { Row } from '../../components/Flex';
import { Header } from '../../components/Header';
import { Loader } from '../../components/Loader';
import { useGetSurveys } from '../../network/useSurvey';
import { Card } from './components/Card';

export const Surveys: FC<RouteComponentProps> = () => {
	const surveys = useGetSurveys();
	const [showInactiveSurveys, setShowInactiveSurveys] = useState<boolean>(false);

	if (surveys.isLoading) {
		return (
			<Container>
				<Loader black />
			</Container>
		);
	}

	return (
		<Container>
			<Header>
				<h1>Surveys</h1>
				<LinkButton to="/surveys/new">
					<FontAwesomeIcon icon={faPlusCircle} />
					Create new survey
				</LinkButton>
			</Header>
			<Row style={{ marginBottom: 24 }}>
				<OutlinedButton active={showInactiveSurveys} onClick={() => setShowInactiveSurveys(cs => !cs)}>
					{showInactiveSurveys ? 'Hide inactive surveys' : 'Show inactive surveys'}
				</OutlinedButton>
			</Row>
			<SurveyList>
				{surveys.data
					?.filter(survey => showInactiveSurveys || survey.active)
					.map(survey => (
						<Card key={survey.id} survey={survey} />
					))}
			</SurveyList>
			{surveys.error && <ErrorAlert>{surveys.error.message}</ErrorAlert>}
		</Container>
	);
};

const SurveyList = styled.div`
	display: grid;
	gap: 24px;
	grid-template-columns: repeat(auto-fit, 250px);
`;
