type Options = {
	debugMode: boolean;
	apiUrl: string;
	apiSurveyUrl: string;
	firebaseKey: string;
	environment: string;
}

const localOptions: Options = {
	debugMode: process.env.NODE_ENV === 'development',
	apiUrl: process.env.REACT_APP_API_URL!,
	apiSurveyUrl: process.env.REACT_APP_API_SURVEY_URL!,
	firebaseKey: process.env.REACT_APP_FIREBASE_KEY!,
	environment: 'local',
};

const testOptions: Options = {
	debugMode: false,
	apiUrl: 'https://api.porterbuddy-test.com',
	apiSurveyUrl: 'https://api.survey.porterbuddy-test.com',
	firebaseKey: 'AIzaSyCBomPE274DRpnTKIUeK7XVn2DA1aiShQY',
	environment: 'test',
};

const prodOptions: Options = {
	debugMode: false,
	apiUrl: 'https://api.porterbuddy.com',
	apiSurveyUrl: 'https://api.survey.porterbuddy.com',
	firebaseKey: 'AIzaSyC0LWVYAzBK2uwCwFdGQNHzgy7kFYUvFdY',
	environment: 'prod',
};

let options = prodOptions;

const host = window.location.host;

if (
	host.includes('porterbuddy-test.com') ||
	host.includes('pb-reports-fr-staging')
) {
	options = testOptions;
}

if (host.includes('localhost')) {
	options = localOptions;
}

console.log(`Environment: ${options.environment}`);

export const settings = {
	...options
};