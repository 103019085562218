import firebaseApp from "firebase/app";
import "firebase/auth";
import { settings } from "../settings";

export function initFirebaseApp() {
  const firebaseConfig = {
    apiKey: settings.firebaseKey,
  };

  firebase.initializeApp(firebaseConfig);
}

export const firebase = firebaseApp;

export const auth = () => firebase.auth();
