import { faStar as faStarOutline } from '@fortawesome/free-regular-svg-icons';
import {
	faArrowCircleRight,
	faClipboardList,
	faQuestionCircle,
	faStar as faStarFilled,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@reach/router';
import { lighten, readableColor } from 'polished';
import React, { FC } from 'react';
import styled from 'styled-components';
import { InfoBox } from '../../../components/InfoBox';
import { Survey as SurveyType } from '../../../network/useSurvey';
import { stringToColor } from '../../../styles/stringToColor';
import { useFavorites } from '../../../utils/useFavorites';
import { useTheme } from '../../../utils/useTheme';

export const Card: FC<{ survey: SurveyType }> = ({ survey }) => {
	const { theme } = useTheme();
	const { favoriteSurveys, addOrRemoveSurveyToFavorites } = useFavorites();
	return (
		<StyledCard to={`/surveys/${survey.id}`}>
			<CardTitle colorString={survey.display_name}>
				<FavoriteIcon>
					<button
						onClick={event => {
							event.preventDefault();
							addOrRemoveSurveyToFavorites(survey.id);
						}}
					>
						<FontAwesomeIcon
							icon={favoriteSurveys.includes(survey.id) ? faStarFilled : faStarOutline}
							color={readableColor(
								lighten(0.2, stringToColor(survey.display_name)),
								theme.colors.text.black,
								theme.colors.text.white
							)}
						/>
					</button>
				</FavoriteIcon>
				<h2>{survey.display_name}</h2>
			</CardTitle>
			<CardContent>
				<div>
					<InfoBox title="Number of questions">
						<FontAwesomeIcon icon={faQuestionCircle} />
						<span>{survey.question_count}</span>
					</InfoBox>
					<div style={{ width: 16 }} />
					<InfoBox title="Number of responses">
						<FontAwesomeIcon icon={faClipboardList} />
						<span>{survey.response_count}</span>
					</InfoBox>
				</div>
				<InfoBox>
					<FontAwesomeIcon icon={faArrowCircleRight} />
				</InfoBox>
			</CardContent>
		</StyledCard>
	);
};

const StyledCard = styled(Link)`
	width: 250px;
	border-radius: 1rem;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	transition: all 200ms ease-in-out;
	box-shadow: ${({ theme }) => theme.shadow.s};

	&:hover {
		box-shadow: ${({ theme }) => theme.shadow.l};
	}
`;

const CardTitle = styled.div<{ colorString: string }>`
	width: 100%;
	height: 100px;
	padding: 0.5rem 0.5rem 8px 16px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	background-color: ${({ colorString }) => lighten(0.2, stringToColor(colorString))};

	h2 {
		font-size: 1.4rem;
		margin: 0;
		font-weight: bold;
		color: ${props =>
			readableColor(
				lighten(0.2, stringToColor(props.colorString)),
				props.theme.colors.text.black,
				props.theme.colors.text.white
			)};
	}

	${StyledCard}:hover {
		padding: 0.6rem 1.1rem;
	}
`;

const FavoriteIcon = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

const CardContent = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 2rem 1rem 1rem 1rem;

	div {
		display: flex;
	}
`;
