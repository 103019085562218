import { Link as ReachRouterLink } from '@reach/router';
import { readableColor, transparentize } from 'polished';
import styled from 'styled-components';

export const Button = styled.button<{ size?: 'xs' | 's' | 'm' | 'l' }>`
	display: flex;
	align-items: center;
	padding: 8px 16px;
	border-radius: 48px;
	background-color: ${({ theme }) => theme.colors.porter.primary};
	color: ${({ theme }) => theme.colors.text.white};
	border: 2px solid ${({ theme }) => theme.colors.porter.primary};
	transition: background-color 300ms ease-in-out;
	transition: color 100ms ease-in-out;

	${({ size }) => {
		switch (size) {
			case 'xs':
				return {
					padding: '2px 10px',
					maxHeight: 38,
				};
			case 's':
				return {
					padding: '3px 13px',
				};
			case 'm':
				// Ignoring case "m" because that is the default size
				return;
			case 'l':
				return {
					fontSize: 20,
				};
		}
	}}

	&:hover {
		background-color: ${({ theme }) => transparentize(0.2, theme.colors.porter.primary)};
	}

	&:active {
		background-color: ${({ theme }) => transparentize(0.1, theme.colors.porter.primary)};
	}

	.svg-inline--fa {
		margin-right: 16px;
	}
`;

export const LinkButton = styled(ReachRouterLink)<{ size?: 's' | 'm' | 'l' }>`
	display: flex;
	align-items: center;
	padding: 8px 16px;
	border-radius: 3rem;
	background-color: ${({ theme }) => theme.colors.porter.primary};
	color: ${({ theme }) => theme.colors.text.white};
	border: 2px solid ${({ theme }) => theme.colors.porter.primary};
	transition: background-color 300ms ease-in-out;
	transition: color 100ms ease-in-out;

	${({ size }) => {
		if (size === 's') {
			return {
				padding: '0.2rem 0.8rem',
			};
		}

		// Ignoring case "m" because that is the default size

		if (size === 'l') {
			return {
				fontSize: 20,
			};
		}
	}}

	&:hover {
		background-color: ${({ theme }) => transparentize(0.2, theme.colors.porter.primary)};
	}

	&:active {
		background-color: ${({ theme }) => transparentize(0.1, theme.colors.porter.primary)};
	}

	.svg-inline--fa {
		margin-right: 16px;
	}
`;

export const OutlinedButton = styled(Button)<{ active?: boolean }>`
	background-color: ${({ theme, active }) => (active ? theme.colors.porter.primary : 'transparent')};
	color: ${({ theme, active }) => (active ? theme.colors.text.white : theme.colors.text.primary)};

	&:hover {
		background-color: ${({ theme }) => transparentize(0.9, theme.colors.porter.primary)};
		color: ${({ theme }) =>
			readableColor(theme.colors.background.secondary, theme.colors.text.white, theme.colors.text.black)};
	}

	&:active {
		background-color: ${({ theme }) => transparentize(0.5, theme.colors.porter.primary)};
		color: ${({ theme }) => theme.colors.text.white};
	}
`;

export const DangerOutlinedButton = styled(Button)`
	background-color: transparent;
	color: ${({ theme }) => theme.colors.text.primary};
	border-color: ${({ theme }) => theme.colors.red.primary};

	&:hover {
		background-color: ${({ theme }) => transparentize(0.9, theme.colors.red.primary)};
		color: ${({ theme }) =>
			readableColor(theme.colors.background.secondary, theme.colors.text.white, theme.colors.text.black)};
	}

	&:active {
		background-color: ${({ theme }) => transparentize(0.5, theme.colors.red.primary)};
		color: ${({ theme }) => theme.colors.text.white};
	}
`;

export const IconButton = styled.button<{ active?: boolean }>`
	display: flex;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.background.tertiary};
	border-radius: 20px;
	padding: 5px 6px;
	border: 1px solid ${({ theme }) => theme.colors.porter.primary};

	span {
		margin-left: 6px;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	max-width: 100%;
	overflow-x: auto;

	button {
		border-radius: 0;
		border-right-width: 0;

		&:first-child {
			border-top-left-radius: 3rem;
			border-bottom-left-radius: 3rem;
		}

		&:last-child {
			border-top-right-radius: 3rem;
			border-bottom-right-radius: 3rem;
			border-right-width: 2px;
		}
	}
`;
