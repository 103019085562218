import { RouteComponentProps } from '@reach/router';
import { FC, useState } from 'react';
import { ErrorAlert } from '../../components/Alerts';
import { ButtonWrapper, OutlinedButton } from '../../components/Buttons';
import { Container } from '../../components/Container';
import { Header } from '../../components/Header';
import { Loader } from '../../components/Loader';
import { useGetSurvey } from '../../network/useSurvey';
import { Answers } from './Answers';
import { Questions } from './components/Questions';

export const Survey: FC<RouteComponentProps & { surveyId?: string }> = ({ surveyId }) => {
	const surveyIdAsNumber = parseInt(surveyId ?? '-1', 10);
	const { isLoading, data } = useGetSurvey(surveyIdAsNumber);
	const [mode, setMode] = useState<'questions' | 'answers'>('answers');

	if (isLoading) {
		return (
			<Container>
				<Loader black />
			</Container>
		);
	}

	if (!data || !surveyId) {
		return (
			<Container>
				<ErrorAlert>Something went wrong</ErrorAlert>
			</Container>
		);
	}

	if (data) {
		return (
			<Container>
				<Header>
					<h1>{data.display_name}</h1>
					<ButtonWrapper>
						<OutlinedButton active={mode === 'questions'} onClick={() => setMode('questions')}>
							Questions
						</OutlinedButton>
						<OutlinedButton active={mode === 'answers'} onClick={() => setMode('answers')}>
							Answers
						</OutlinedButton>
					</ButtonWrapper>
				</Header>
				{data.description && (
					<>
						<h3>Description</h3>
						<p>{data.description}</p>
					</>
				)}
				<div style={{ height: 16 }} />
				{mode === 'questions' && <Questions questions={data.questions} surveyId={surveyId} />}
				{mode === 'answers' && <Answers surveyId={surveyIdAsNumber} />}
			</Container>
		);
	}

	return null;
};
