import { isBefore, subDays } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ErrorAlert } from '../../components/Alerts';
import { ListWrapper } from '../../components/Lists';
import { Loader } from '../../components/Loader';
import { Answer as AnswerType, QuestionWithAnswer, useGetAnswers } from '../../network/useSurvey';
import { ChartWrapper } from './components/Charts';
import { DateSelector } from './components/DateSelector';
import { NumberOfAnswers } from './components/NumberOfAnswers';

export const Answers: FC<{ surveyId: number }> = ({ surveyId }) => {
	const now = new Date();
	const [startDate, setStartDate] = useState<Date>(subDays(now, 7));
	const [endDate, setEndDate] = useState<Date>(now);

	const { isLoading, error, data, refetch } = useGetAnswers(surveyId, startDate, endDate);

	useEffect(() => {
		if (isBefore(startDate, endDate)) {
			refetch();
		}

		if (isBefore(endDate, startDate)) {
			setEndDate(startDate);
		}
	}, [startDate, endDate, refetch]);

	if (isLoading) {
		<Loader black />;
	}

	if (error) {
		return <ErrorAlert>{error.message || 'Something went wrong'}</ErrorAlert>;
	}

	if (data) {
		const { timesOffered, numberOfUniqueAnswers } = data;

		return (
			<>
				<h2>Answers</h2>
				<MenuWrapper>
					<DateSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
					<NumberOfAnswers timesOffered={timesOffered} numberOfUniqueAnswers={numberOfUniqueAnswers} />
				</MenuWrapper>
				{data.questionsWithAnswers
					.sort((a, b) => (a.meta_data?.sort_order || 0) - (b.meta_data?.sort_order || 0))
					.map(d => (
						<Answer key={d.id} data={d} />
					))}
			</>
		);
	}

	return null;
};

const MenuWrapper = styled.div`
	display: flex;
	flex-direction: column;

	@media only screen and (max-width: 500px) {
		margin-bottom: 1rem;
	}
`;

const Answer: FC<{ data: QuestionWithAnswer }> = ({ data }) => {
	const comments = data.answers.map(answer => answer.comment).filter(Boolean);
	const commentsEnabled = data.alternatives.some(alternative => alternative.meta_data.canComment);

	return (
		<StyledAnswer>
			{(() => {
				switch (data.type) {
					case 'FREE_TEXT':
						return <FreeTextAnswer answers={data.answers} question={data.question} />;
					case 'CHECKBOX':
						return (
							<div>
								<h4>{data.question}</h4>
								<div>Checkbox</div>
							</div>
						);
					case 'RADIO':
					case 'LIKERT_SCALE':
						return <ChartWrapper data={data} />;
				}
			})()}
			{commentsEnabled && (
				<div>
					<h4 style={{ marginBottom: '1rem' }}>Comments</h4>
					{comments.length === 0 && 'No comments'}
					<ListWrapper>
						{comments.map(comment => (
							<Comment key={comment}>{comment}</Comment>
						))}
					</ListWrapper>
				</div>
			)}
		</StyledAnswer>
	);
};

const StyledAnswer = styled.div`
	&:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.colors.border};
		padding-bottom: 1rem;
		margin-bottom: 2rem;
	}
`;

const Comment = styled.div`
	padding: 1rem;
	&:hover {
		background-color: ${({ theme }) => theme.colors.background.tertiary};
	}
`;

const FreeTextAnswer: FC<{ answers: AnswerType[]; question: string }> = ({ answers, question }) => {
	return (
		<>
			<h4>{question}</h4>
			{answers.map((answer, index) => (
				<p key={index}>{answer.free_text_value}</p>
			))}
		</>
	);
};
