import { CSSProperties, FC, useCallback, useEffect, useState } from 'react';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';

export const InteractiveBackground: FC<{ style?: CSSProperties }> = ({ style, children }) => {
	const [ref, bounds] = useMeasure();

	const [coords, setCoords] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
	const [angle, setAngle] = useState<number>(30);
	const [intensity, setIntensity] = useState<number>(50);

	const mouseMoveListener = useCallback(({ clientX, clientY }: { clientX: number; clientY: number }) => {
		setCoords({ x: clientX, y: clientY });
	}, []);

	useEffect(() => {
		window.addEventListener('mousemove', mouseMoveListener);

		return () => {
			window.removeEventListener('mousemove', mouseMoveListener);
		};
	}, [mouseMoveListener]);

	useEffect(() => {
		const x = bounds.x + coords.x;
		const y = bounds.y + coords.y;
		const distance = Math.sqrt(x * x + y * y);
		setAngle((Math.atan2(bounds.width - coords.y, bounds.height - coords.x) * 180) / Math.PI);
		setIntensity((100 / bounds.width) * distance);
	}, [bounds, coords]);

	return (
		<Container
			ref={ref}
			style={{
				background: `linear-gradient(
						${angle}deg,
						${gradientColors(intensity)}
					)`,
				...style,
			}}
		>
			{children}
		</Container>
	);
};

const gradientColors = (intensity: number): string => {
	const today = new Date();

	if (today.getMonth() === 5) {
		return `
		#ff0000 0%,
		#ffa500 ${intensity / 4}%,
		#ffff00 ${(intensity / 4) * 2}%,
		#008000 ${(intensity / 4) * 3}%,
		#0000ff ${intensity}%,
		#800080 100%`;
	}

	if (today.getMonth() === 4 && today.getDate() === 17) {
		return `
		#bb042b 0%,
		#ffffff ${intensity / 3}%,
		#001a5b ${(intensity / 3) * 2}%,
		#ffffff ${(intensity / 3) * 3}%,
		#bb042b 100%`;
	}

	if (today.getMonth() === 10 && today.getDate() === 11) {
		return `
		#ffffff 0%,
		#dc143c 100%`;
	}

	return `
	#722bff 0%,
	#661aff ${intensity}%,
	#5b09ff 100%`;
};

const Container = styled.div`
	height: 100vh;
	width: 100%;
	background: #661aff;
`;
