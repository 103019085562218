import { RouteComponentProps } from '@reach/router';
import { ChartOptions } from 'chart.js';
import { isBefore, subDays } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { ErrorAlert } from '../../components/Alerts';
import { ButtonWrapper, OutlinedButton } from '../../components/Buttons';
import { Checkbox } from '../../components/inputs/Checkbox';
import { Container } from '../../components/Container';
import { Autosuggest } from '../../components/inputs/Autosuggest';
import { Loader } from '../../components/Loader';
import { DateCount, useGetDeliveriesByAccount } from '../../network/useReport';
import { stringToColor } from '../../styles/stringToColor';
import { useTheme } from '../../utils/useTheme';
import { DateSelector } from '../surveys/components/DateSelector';

export const Volumes: FC<RouteComponentProps> = () => {
	const { theme } = useTheme();
	const now = new Date();
	const [startDate, setStartDate] = useState<Date>(subDays(now, 7));
	const [endDate, setEndDate] = useState<Date>(now);
	const [stacked, setStacked] = useState<boolean>(true);
	const [availableRegions, setAvailableRegions] = useState<string[]>([]);
	const [selectedRegion, setSelectedRegion] = useState<string>('ALL');
	const [value, setValue] = useState<string>('');
	const { isLoading, error, data, refetch } = useGetDeliveriesByAccount(startDate, endDate);

	useEffect(() => {
		if (isBefore(startDate, endDate)) {
			refetch();
		}

		if (isBefore(endDate, startDate)) {
			setEndDate(startDate);
		}
	}, [startDate, endDate, refetch]);

	useEffect(() => {
		if (data) {
			setAvailableRegions(data.regionCounts.map(({ region }) => region));
		}
	}, [data]);

	if (isLoading) {
		return <Loader black />;
	}

	if (error) {
		return <ErrorAlert>{error.message || 'Something went wrong'}</ErrorAlert>;
	}

	if (data) {
		const { dateCounts, regionCounts } = data;

		const options: ChartOptions = {
			tooltips: { mode: 'index' },
			legend: {
				display: true,
				labels: {
					fontColor: theme.colors.text.primary,
				},
			},
			scales: {
				yAxes: [
					{
						stacked,
						ticks: {
							beginAtZero: true,
						},
					},
				],
				xAxes: [
					{
						stacked,
					},
				],
			},
		};

		const dateCountData =
			selectedRegion === 'ALL'
				? dateCounts
				: regionCounts.find(({ region }) => region === selectedRegion)?.orderDateByAccountCount || [];
		const chartData = dataToChartData(dateCountData, []);

		return (
			<Container>
				<h2>Volumes</h2>
				<DateSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
				<ButtonWrapper>
					<OutlinedButton active={selectedRegion === 'ALL'} onClick={() => setSelectedRegion('ALL')}>
						ALL
					</OutlinedButton>
					{availableRegions.map(region => (
						<OutlinedButton
							key={region}
							size="s"
							active={selectedRegion === region}
							onClick={() => setSelectedRegion(region)}
						>
							{region.split('_')[0]}
						</OutlinedButton>
					))}
				</ButtonWrapper>
				<Autosuggest
					suggestions={data.accountNames.map(account => ({
						name: account,
					}))}
					inputProps={{
						placeholder: 'Accounts',
						onChange: (_, { newValue }) => {
							setValue(newValue);
						},
						value,
					}}
				/>
				<Checkbox
					checked={stacked}
					onChange={() => setStacked(cs => !cs)}
					title="Stacked"
					style={{ marginTop: '1rem' }}
				/>
				<Bar data={chartData} options={options} />
			</Container>
		);
	}

	return null;
};

type Dataset = {
	label: string;
	data: number[];
	backgroundColor: string;
};

type ChartData = {
	labels: string[];
	datasets: Dataset[];
};

const dataToChartData = (data: DateCount[], retailers: string[]): ChartData => {
	const labels = data.map(dateCount => dateCount.date);
	let datasets: {
		label: string;
		data: number[];
		backgroundColor: string;
	}[] = [];

	data.forEach(dateCount => {
		if (retailers.length === 0) {
			const count = dateCount.counts.reduce((acc, cur) => acc + cur.count, 0);

			if (datasets.length === 0) {
				datasets.push({
					label: 'Total',
					data: [count],
					backgroundColor: stringToColor('Total'),
				});
			} else {
				datasets[0] = {
					...datasets[0],
					data: [...datasets[0].data, count],
				};
			}
		} else {
			dateCount.counts
				.filter(count => retailers.includes(count.accountName))
				.forEach(count => {
					const datasetIndex = datasets.findIndex(dataset => dataset.label === count.accountName);
					const labelIndex = labels.findIndex(label => label === count.date);

					if (datasetIndex === -1) {
						const datasetDataArray = new Array(labels.length).fill(0);
						datasetDataArray[labelIndex] = count.count;

						const dataset = {
							label: count.accountName,
							data: datasetDataArray,
							backgroundColor: stringToColor(count.accountName),
						};

						datasets.push(dataset);
					} else {
						const dataset = datasets[datasetIndex];
						const datasetLabelIndex = dataset.data[labelIndex];
						const newCount = datasetLabelIndex + count.count;

						datasets[datasetIndex].data[labelIndex] = newCount;
					}
				});
		}
	});

	return {
		labels,
		datasets,
	};
};
