import styled from 'styled-components';

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 1rem 0 2rem 0;

	h1 {
		margin: 0;
		line-height: 1;
	}
`;
