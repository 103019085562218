import { CSSProperties, FC } from 'react';
import styled from 'styled-components';

export const Textarea: FC<{
	value: string | undefined;
	label: string;
	onChange: (text: string) => void;
	style?: CSSProperties;
	onBlur?: () => void;
	disabled?: boolean;
	placeholder?: string;
	labelOnlyForScreenReaders?: boolean;
}> = ({ value, onChange, style, onBlur, label, disabled, placeholder, labelOnlyForScreenReaders }) => (
	<StyledInput style={style}>
		<span className={labelOnlyForScreenReaders ? 'screenReaderOnly' : undefined}>{label}</span>
		<textarea
			value={value || ''}
			onBlur={() => {
				onBlur && onBlur();
			}}
			onChange={event => onChange(event.target.value)}
			placeholder={placeholder}
			disabled={disabled}
		/>
	</StyledInput>
);

const StyledInput = styled.label`
	font-size: 18px;
	display: flex;
	flex-direction: column;
	margin-bottom: 8px;

	textarea {
		border-radius: 4px;
		border: 1px solid ${props => props.theme.colors.border};
		padding: 8px;
		padding-right: 8px;
		background-color: transparent;
		color: ${props => props.theme.colors.text.primary};
	}
`;
