import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import porterbuddyLogo from '../assets/porterbuddy.svg';
import { ErrorAlert } from '../components/Alerts';
import { Input } from '../components/inputs/Input';
import { InteractiveBackground } from '../components/InteractiveBackground';
import { useAuthStore } from '../store/auth';

export const SignIn: FC = () => {
	const { state, signIn } = useAuthStore();

	const passwordRef = useRef<HTMLInputElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	useEffect(() => {
		emailRef?.current?.focus();
	}, []);

	return (
		<InteractiveBackground
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<PorterbuddyLogoImg src={porterbuddyLogo} alt="Porterbuddy" />
			<SignInContainer>
				<h2>Sign in</h2>
				<Input
					label="E-mail"
					type="text"
					value={email}
					ref={emailRef}
					onChange={text => setEmail(text)}
					autoComplete="username"
				/>
				<Input
					label="Password"
					type="password"
					value={password}
					ref={passwordRef}
					onChange={text => setPassword(text)}
					autoComplete="current-password"
				/>
				{state.type === 'error' && <ErrorAlert>{state.error}</ErrorAlert>}
				<button onClick={() => signIn(email, password)}>Sign in</button>
			</SignInContainer>
		</InteractiveBackground>
	);
};

const PorterbuddyLogoImg = styled.img`
	width: 100px;
	position: absolute;
	top: 16px;
	left: 16px;
`;

const SignInContainer = styled.form`
	display: flex;
	flex-direction: column;
	width: 300px;
	background-color: ${({ theme }) => theme.colors.background.secondary};
	padding: 32px;
	border-radius: 16px;

	h2 {
		margin: 0 0 16px 0;
		color: ${({ theme }) => theme.colors.text.primary};
	}

	input {
		font-size: 1rem;
		border: 1px solid ${({ theme }) => theme.colors.border};
		padding: 8px;
		border-radius: 8px;
		margin-bottom: 8px;
	}

	button {
		font-size: 1rem;
		margin-top: 8px;
		border: 1px solid ${({ theme }) => theme.colors.border};
		padding: 8px;
		border-radius: 8px;
	}
`;
