import axios from 'axios';
import { settings } from '../settings';

export const porterApi = (token?: string) => {
	return axios.create({
		baseURL: settings.apiUrl,
		headers: {
			authorization: token,
		},
	});
};

export const surveyApi = (token?: string) => {
	return axios.create({
		baseURL: settings.apiSurveyUrl,
		headers: {
			authorization: token,
		},
	});
};
