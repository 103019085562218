import styled from 'styled-components';

export const InfoBox = styled.div<{ backgroundColor?: string }>`
	display: flex;
	align-items: center;
	background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.background.tertiary};
	border-radius: 20px;
	padding: 5px 6px;

	span {
		margin-left: 6px;
	}
`;
