import styled from 'styled-components';

export const ListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	box-shadow: ${({ theme }) => theme.shadow.xs};
	border-radius: 8px;
	overflow: hidden;

	div:last-child {
		border: 0;
	}
`;
