import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import authClient, { AuthHeadersType } from '../network/authClient';
import { settings } from '../settings';
import { porterApi } from '../utils/api';
import storage from '../utils/storage';

export const AUTH_HEADERS = '@PorterbuddyStore:AUTH_HEADERS';

type UserType = {
	email: string;
	role: 'ADMIN';
	limitedAccess: boolean;
};

type AuthState =
	| {
			type: 'signedOut';
	  }
	| {
			type: 'signedIn';
			user: UserType;
			authHeaders: AuthHeadersType;
	  }
	| {
			type: 'loading';
	  }
	| {
			type: 'error';
			error: string;
	  };

type UseAuthType = {
	state: AuthState;
	signIn: (email: string, password: string) => Promise<void>;
	signOut: () => Promise<void>;
};

const useAuth = (): UseAuthType => {
	const [state, setState] = useState<AuthState>({ type: 'signedOut' });

	useEffect(() => {
		(async () => {
			const authHeaders = storage.getData<AuthHeadersType | null>(AUTH_HEADERS, null);
			setState({
				type: 'loading',
			});
			if (authHeaders) {
				try {
					const userResponse = await porterApi(authHeaders?.Authorization).get<UserType>('/user/access');

					if (userResponse.data.role !== 'ADMIN' || userResponse.data.limitedAccess) {
						setState({
							type: 'error',
							error: 'You are trying to login with an unsupported account type, please login as a dispatcher',
						});

						void storage.deleteData(AUTH_HEADERS);
						await authClient.signOut();

						if (settings.debugMode) {
							console.log('Signed out due to wrong credentials');
						}
						return;
					}

					setState({
						type: 'signedIn',
						authHeaders,
						user: userResponse.data,
					});
				} catch (_err) {
					const err = _err as AxiosError;
					if ([401, 403].includes(err.response?.status ?? -1)) {
						setState({
							type: 'error',
							error: err.message || 'Could not sign in. Wrong username or password',
						});
					}
				}
			} else {
				setState({ type: 'signedOut' });
			}
		})().catch(err => {
			console.error('Error checking login status: ', err);
			setState({
				type: 'error',
				error: 'Something went wrong',
			});
			// Add logging. For example sentry
		});
	}, []);

	const signIn = useCallback(async (email: string, password: string): Promise<void> => {
		setState({
			type: 'loading',
		});
		const signInResult = await authClient.signIn(email, password);
		if (signInResult.success) {
			const newAuthHeaders = signInResult.data?.authHeaders;
			void storage.storeData(AUTH_HEADERS, newAuthHeaders);

			if (!newAuthHeaders) {
				setState({
					type: 'signedOut',
				});
			} else {
				const userResponse = await porterApi(newAuthHeaders?.Authorization).get<UserType>('/user/access');

				if (userResponse.data.role !== 'ADMIN') {
					setState({
						type: 'error',
						error: 'You are trying to login with a not supported account, please login as a dispatcher',
					});

					void storage.deleteData(AUTH_HEADERS);
					await authClient.signOut();

					if (settings.debugMode) {
						console.log('Signed out due to wrong credentials');
					}
					return;
				}

				setState({
					type: 'signedIn',
					authHeaders: newAuthHeaders,
					user: userResponse.data,
				});
			}
		} else {
			setState({
				type: 'error',
				error: signInResult.errorMessage ?? 'Something went wrong',
			});
		}
	}, []);

	const signOut = useCallback(async (): Promise<void> => {
		try {
			setState({
				type: 'signedOut',
			});
			void storage.deleteData(AUTH_HEADERS);
			await authClient.signOut();
			if (settings.debugMode) {
				console.log('Signed out from Firebase');
			}
		} catch (e) {
			if (settings.debugMode) {
				console.log('Error signing out from Firebase', e);
			}
			// Add logging. For example sentry
		}
	}, []);

	return {
		state,
		signIn,
		signOut,
	};
};

const AuthContainer = createContainer(useAuth);
export const AuthProvider = AuthContainer.Provider;
export const useAuthStore = (): UseAuthType => AuthContainer.useContainer();
