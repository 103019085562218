import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import { useSurveyApi } from './../utils/useApi';
import { axiosErrorMessage } from './utils';

export type Survey = {
	id: number;
	name: string;
	active: boolean;
	display_name: string;
	description?: string;
	meta_data: Record<string, unknown>;
	question_count: number;
	response_count: number;
};

export const useGetSurveys = () => {
	const surveyApi = useSurveyApi();

	return useQuery<Survey[], AxiosError>('surveysFetch', () => surveyApi.get('/surveys').then(res => res.data), {
		staleTime: 600000,
	});
};

type Emoji = ':smile_face:' | ':happy_face:' | ':neutral_face:';

export type AlternativeMetaData = {
	emoji?: Emoji;
	textColor?: string;
	canComment?: boolean;
	backgroundColor?: string;
	multiline?: boolean;
};

export type Alternative = {
	id: number;
	created_at: string;
	updated_at: string;
	question_id: number;
	alternative: string;
	value: number;
	meta_data: AlternativeMetaData;
};

export type QuestionType = 'CHECKBOX' | 'RADIO' | 'LIKERT_SCALE' | 'FREE_TEXT';

export type QuestionMetaData = {
	required?: boolean;
	sort_order?: number;
	show_only_if?: number;
};

export type Question = {
	id: number;
	active: boolean;
	question: string;
	description?: string;
	type: QuestionType;
	alternatives: Alternative[];
	meta_data: QuestionMetaData;
};

export type SurveyWithQuestions = Survey & { questions: Question[] };

export const useGetSurvey = (id: number) => {
	const surveyApi = useSurveyApi();

	return useQuery<SurveyWithQuestions, AxiosError>(
		['surveyFetch', id],
		() => surveyApi.get(`/surveys/${id}`).then(res => res.data),
		{
			staleTime: 600000,
		}
	);
};

export type QuestionWithAnswer = {
	id: number;
	survey_id: number;
	active: boolean;
	question: string;
	description: null | string;
	type: QuestionType;
	meta_data: AnswerMetaData;
	alternatives: Alternative[];
	answers: Answer[];
};

export type Answer = {
	id: number;
	created_at: string;
	updated_at: string;
	result_id: number;
	question_id: number;
	alternative_id: number;
	comment: null;
	value: number | null;
	alternative: string;
	meta_data: AlternativeMetaData;
	alternative_value: number | null;
	free_text_value: string | null;
};

type AnswerMetaData = {
	required?: boolean;
	sort_order?: number;
};

type GetAnswers = {
	timesOffered: number;
	numberOfUniqueAnswers: number;
	questionsWithAnswers: QuestionWithAnswer[];
};

export const useGetAnswers = (id: number, startDate?: Date | null, endDate?: Date | null) => {
	const surveyApi = useSurveyApi();

	return useQuery<GetAnswers, AxiosError>(
		['answersFetch', id],
		() =>
			surveyApi
				.get(`/surveys/${id}/answers`, {
					params: {
						fromDate: startDate ? format(startDate, 'yyyy-MM-dd') : undefined,
						toDate: endDate ? format(endDate, 'yyyy-MM-dd') : undefined,
					},
				})
				.then(res => res.data),
		{
			staleTime: 60000,
		}
	);
};

type CreateSurveyBody = {
	displayName: string;
	name: string;
	description?: string;
	meta_data?: Record<string, unknown>;
};

type EditSurveyBody = {
	active: boolean;
	display_name: string;
	description?: string;
	meta_data?: Record<string, unknown>;
};

type CreateQuestionBody = {
	question: string;
	description?: string;
	type: QuestionType;
	meta_data: QuestionMetaData;
};

type CreateAlternativeBody = {
	alternative: string;
	value?: number;
	meta_data?: AlternativeMetaData;
};

type CreateSurveyResponse =
	| {
			success: true;
			data: {
				id: number;
			};
	  }
	| { success: false; error: string };

type EditSurveyResponse =
	| {
			success: true;
	  }
	| { success: false; error: string };

type QuestionResponse = {
	id: number;
	active: boolean;
	question: string;
	description?: string;
	type: QuestionType;
	meta_data: QuestionMetaData;
};

type AddQuestionResponse =
	| {
			success: true;
			data: QuestionResponse;
	  }
	| { success: false; error: string };

type EditQuestionResponse =
	| {
			success: true;
	  }
	| { success: false; error: string };

type DeleteQuestionResponse =
	| {
			success: true;
	  }
	| { success: false; error: string };

type AddAlternativeResponse =
	| {
			success: true;
			data: Alternative;
	  }
	| { success: false; error: string };

type EditAlternativeResponse =
	| {
			success: true;
	  }
	| { success: false; error: string };

type DeleteAlternativeResponse =
	| {
			success: true;
	  }
	| { success: false; error: string };

export const useCreateSurvey = () => {
	const surveyApi = useSurveyApi();

	const createSurvey = async (body: CreateSurveyBody): Promise<CreateSurveyResponse> => {
		try {
			const { data } = await surveyApi.post('surveys/create', body);

			return {
				success: true,
				data,
			};
		} catch (e) {
			return {
				success: false,
				error: axiosErrorMessage(e, 'Could not create survey'),
			};
		}
	};

	const editSurvey = async (surveyId: number, body: EditSurveyBody): Promise<EditSurveyResponse> => {
		try {
			await surveyApi.put<QuestionResponse>(`surveys/${surveyId}`, body);

			return {
				success: true,
			};
		} catch (e) {
			return {
				success: false,
				error: axiosErrorMessage(e, 'Could not edit survey'),
			};
		}
	};

	const addQuestion = async (surveyId: number, body: CreateQuestionBody): Promise<AddQuestionResponse> => {
		try {
			const { data } = await surveyApi.post<QuestionResponse>(`surveys/${surveyId}/questions`, body);

			return {
				success: true,
				data,
			};
		} catch (e) {
			return {
				success: false,
				error: axiosErrorMessage(e, 'Could not add question'),
			};
		}
	};

	const editQuestion = async (
		surveyId: number,
		questionId: number,
		body: CreateQuestionBody
	): Promise<EditQuestionResponse> => {
		try {
			await surveyApi.put<QuestionResponse>(`surveys/${surveyId}/questions/${questionId}`, body);

			return {
				success: true,
			};
		} catch (e) {
			return {
				success: false,
				error: axiosErrorMessage(e, 'Could not add question'),
			};
		}
	};

	const deleteQuestion = async (surveyId: number, questionId: number): Promise<DeleteQuestionResponse> => {
		try {
			await surveyApi.delete(`surveys/${surveyId}/questions/${questionId}`);

			return {
				success: true,
			};
		} catch (e) {
			return {
				success: false,
				error: axiosErrorMessage(e, 'Could not delete question'),
			};
		}
	};

	const addAlternative = async (
		surveyId: number,
		questionId: number,
		body: CreateAlternativeBody
	): Promise<AddAlternativeResponse> => {
		try {
			const { data } = await surveyApi.post(`surveys/${surveyId}/questions/${questionId}/alternatives`, body);

			return {
				data,
				success: true,
			};
		} catch (e) {
			return {
				success: false,
				error: axiosErrorMessage(e, 'Could not add alternative'),
			};
		}
	};

	const editAlternative = async (
		surveyId: number,
		questionId: number,
		alternativeId: number,
		body: CreateAlternativeBody
	): Promise<EditAlternativeResponse> => {
		try {
			await surveyApi.put(`surveys/${surveyId}/questions/${questionId}/alternatives/${alternativeId}`, body);

			return {
				success: true,
			};
		} catch (e) {
			return {
				success: false,
				error: axiosErrorMessage(e, 'Could not add question'),
			};
		}
	};

	const deleteAlternative = async (
		surveyId: number,
		questionId: number,
		alternativeId: number
	): Promise<DeleteAlternativeResponse> => {
		try {
			await surveyApi.delete(`surveys/${surveyId}/questions/${questionId}/alternatives/${alternativeId}`);

			return {
				success: true,
			};
		} catch (e) {
			return {
				success: false,
				error: axiosErrorMessage(e, 'Could not delete alternative'),
			};
		}
	};

	return {
		createSurvey,
		editSurvey,
		addQuestion,
		editQuestion,
		deleteQuestion,
		addAlternative,
		editAlternative,
		deleteAlternative,
	};
};
