import { CSSProperties, FC } from 'react';
import styled from 'styled-components';

export const Input: FC<{
	value: string | number;
	label: string;
	onChange: (text: string) => void;
	autoComplete?: string;
	ref?: React.LegacyRef<HTMLInputElement>;
	onBlur?: () => void;
	size?: 's' | 'm' | 'l';
	style?: CSSProperties;
	type?: string;
	disabled?: boolean;
	placeholder?: string;
	labelOnlyForScreenReaders?: boolean;
}> = ({
	value,
	onChange,
	label,
	ref,
	autoComplete,
	onBlur,
	size = 'm',
	style,
	type = 'text',
	disabled,
	placeholder,
	labelOnlyForScreenReaders,
}) => (
	<StyledInput size={size} style={style} title={label}>
		<span className={labelOnlyForScreenReaders ? 'screenReaderOnly' : undefined}>{label}</span>
		<input
			ref={ref}
			type={type}
			value={value ?? ''}
			onChange={event => onChange(event.target.value)}
			onBlur={() => {
				onBlur && onBlur();
			}}
			placeholder={placeholder}
			disabled={disabled}
			autoComplete={autoComplete}
		/>
	</StyledInput>
);

const StyledInput = styled.label<{ size?: 's' | 'm' | 'l' }>`
	font-size: ${({ size }) => (size === 's' && '14px') || (size === 'l' && '22px') || '18px'};
	display: flex;
	flex-direction: column;
	margin-bottom: ${({ size }) => (size === 's' && '0px') || (size === 'l' && '10px') || '8px'};

	span {
		color: ${props => props.theme.colors.text.primary};
	}

	input {
		border-radius: ${({ size }) => (size === 's' && '2px') || (size === 'l' && '6px') || '4px'};
		border: 1px solid ${props => props.theme.colors.border};
		padding: ${({ size }) => (size === 's' && '4px') || (size === 'l' && '10px') || '8px'};
		background-color: transparent;
		color: ${props => props.theme.colors.text.primary};
	}
`;
