import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';

export const GlobalStyle = createGlobalStyle`
	${normalize()};

	body {
		background-color: ${props => props.theme.colors.background.primary};
		color: ${props => props.theme.colors.text.primary};
	}


	button {
		font: inherit;
		color: inherit;
		background: none;
		cursor: pointer;
		border: none;
		padding: 0;
	}

	button:disabled {
		cursor: default;
	}

	a {
		margin: 0;
		text-decoration: none;
		color: inherit;
	}

	h1 {
		font-weight: bold;
	}

	h2 {
		font-weight: bold;
	} 

	h3, h4, h5, h6 {
		font-weight: 500;
		margin: 0;
	}

	#root {
		width: 100%;
		height: 100%;
	}

	.screenReaderOnly {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
`;
