import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { dark, light, ThemeName } from '../styles/themes';
import storage from '../utils/storage';

export type SystemWidth = 'normal' | 'wide';

const THEME_STORAGE_KEY: string = '@PorterbuddyStore:SELECTED_THEME';
const SYSTEM_WIDTH_STORAGE_KEY: string = '@PorterbuddyStore:SYSTEM_WIDTH';
const SIDE_MENU_EXPANDED_STORAGE_KEY: string = '@PorterbuddyStore:SIDE_MENU_EXPANDED_STORAGE_KEY';

const useUserSettings = () => {
	const [currentTheme, setCurrentTheme] = useState<ThemeName>(storage.getData(THEME_STORAGE_KEY) ?? 'light');
	const [systemWidth, setSystemWidth] = useState<SystemWidth>(storage.getData(SYSTEM_WIDTH_STORAGE_KEY) ?? 'normal');
	const [sideMenuExpanded, setSideMenuExpanded] = useState<boolean>(
		storage.getData(SIDE_MENU_EXPANDED_STORAGE_KEY) ?? false
	);

	return {
		system: {
			width: systemWidth,
			sideMenuExpanded,
			changeSideMenuExpanded: (open?: boolean) => {
				setSideMenuExpanded(cs => {
					const newState = open || !cs;
					storage.storeData(SIDE_MENU_EXPANDED_STORAGE_KEY, newState);
					return newState;
				});
			},
			changeSystemWidth: (width?: SystemWidth) => {
				setSystemWidth(cs => {
					const newWidth = width ? width : cs === 'normal' ? 'wide' : 'normal';
					storage.storeData(SYSTEM_WIDTH_STORAGE_KEY, newWidth);
					return newWidth;
				});
			},
		},
		theme: {
			name: currentTheme === 'light' ? 'light' : 'dark',
			currentTheme: {
				...(currentTheme === 'light' ? light : dark),
				system: {
					width: systemWidth,
					sideMenuExpanded,
				},
			},
			changeTheme: (theme?: ThemeName) => {
				setCurrentTheme(cs => {
					const newTheme = theme ? theme : cs === 'light' ? 'dark' : 'light';
					storage.storeData(THEME_STORAGE_KEY, newTheme);
					return newTheme;
				});
			},
		},
	};
};

const UserSettingsContainer = createContainer(useUserSettings);
export const UserSettingsProvider = UserSettingsContainer.Provider;
export const useUserSettingsStore = () => UserSettingsContainer.useContainer();
