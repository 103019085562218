import axios from 'axios';

export function axiosErrorMessage(e: unknown, fallbackError?: string): string {
	if (axios.isAxiosError(e)) {
		if (e.response?.data?.message) {
			return e.response?.data?.message;
		}
	}

	return fallbackError || 'Something wrong happened';
}
