import { AxiosInstance } from 'axios';
import { useAuthStore } from '../store/auth';
import { porterApi, surveyApi } from './api';

export const usePorterApi = (): AxiosInstance => {
	const { state } = useAuthStore();

	return porterApi(state.type === 'signedIn' ? state.authHeaders.Authorization : undefined);
};

export const useSurveyApi = (): AxiosInstance => {
	const { state } = useAuthStore();

	return surveyApi(state.type === 'signedIn' ? state.authHeaders.Authorization : undefined);
};
