import {
	faArrowRight,
	faBars,
	faChartBar,
	faCog,
	faCompress,
	faExpand,
	faHome,
	faMoon,
	faPollH,
	faSignOutAlt,
	faSun,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, LinkProps } from '@reach/router';
import { lighten, transparentize } from 'polished';
import React, { FC, useEffect, useState } from 'react';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';
import porterbuddyLogo from '../assets/porterbuddy.svg';
import { useAuthStore } from '../store/auth';
import { useUserSettingsStore } from '../store/userSettings';
import { useTheme } from '../utils/useTheme';

export const navigationBackgroundColor = '#2b2b2b';

export const Navigator: FC = () => {
	const [ref, bounds] = useMeasure();
	const { system } = useUserSettingsStore();

	const [mobileMode, setMobileMode] = useState<boolean>(false);

	useEffect(() => {
		if (bounds.width < 1550 && system.sideMenuExpanded) {
			system.changeSystemWidth('wide');
		}

		if (bounds.width < 1233) {
			setMobileMode(true);
		}
	}, [bounds.width, system]);

	return (
		<NavigatorWrapper>
			<NavigatorHeader ref={ref}>
				<div style={{ display: 'flex' }}>
					<HamburgerButton onClick={() => system.changeSideMenuExpanded()}>
						<FontAwesomeIcon icon={system.sideMenuExpanded ? faTimes : faBars} />
					</HamburgerButton>
					<Link to="/">
						<img src={porterbuddyLogo} alt="Porterbuddy" width="100px" />
					</Link>
				</div>
				<TopMenu headerHeight={bounds.height} />
			</NavigatorHeader>
			<SideNavigator headerHeight={bounds.height} mobileMode={mobileMode} />
		</NavigatorWrapper>
	);
};
const HamburgerButton = styled.button`
	@media only screen and (min-width: 1232px) {
		display: none !important;
	}
`;
const NavigatorWrapper = styled.div`
	color: ${({ theme }) => theme.colors.text.white};
	position: fixed;
	top: 0;
	width: 100%;
`;
const NavigatorHeader = styled.div`
	justify-content: space-between;
	background-color: ${navigationBackgroundColor};
	width: 100%;
	display: flex;
	z-index: 100;

	a,
	button {
		display: flex;
		align-items: center;
		height: inherit;
		padding: 1rem;
		font-size: 1.5rem;

		&:hover {
			background-color: ${({ theme }) => transparentize(0.5, theme.colors.porter.primary)} !important;
		}
	}
`;
const TopMenu: FC<{ headerHeight: number }> = ({ headerHeight }) => {
	const { signOut } = useAuthStore();
	const { theme, system } = useUserSettingsStore();

	return (
		<StyledTopMenu headerHeight={headerHeight}>
			<button>
				<FontAwesomeIcon icon={faCog} />
			</button>
			<div className="header__menu">
				<button
					onClick={() => theme.changeTheme()}
					title={`Switch to ${theme.name === 'light' ? 'dark' : 'light'} mode`}
				>
					{theme.name === 'light' ? 'Dark ' : 'Light '} mode
					<span style={{ width: 16 }} />
					<FontAwesomeIcon icon={theme.name === 'light' ? faMoon : faSun} />
				</button>
				<button
					onClick={() => system.changeSystemWidth()}
					title={`Switch to ${system.width === 'normal' ? 'wide' : 'normal'} mode`}
				>
					{system.width === 'normal' ? 'Wide ' : 'Normal '} width
					<span style={{ width: 16 }} />
					<FontAwesomeIcon icon={system.width === 'normal' ? faExpand : faCompress} />
				</button>
				<button onClick={signOut}>
					Sign out
					<span style={{ width: 16 }} />
					<FontAwesomeIcon icon={faSignOutAlt} />
				</button>
			</div>
		</StyledTopMenu>
	);
};
const StyledTopMenu = styled.div<{ headerHeight: number }>`
	position: relative;

	.header__menu {
		display: none;
		position: absolute;
		right: 0;
		top: ${({ headerHeight }) => headerHeight}px;
		background-color: ${lighten(0.05, navigationBackgroundColor)};
		z-index: 90;
		font-size: 1rem;
		white-space: nowrap;

		a,
		button {
			font-size: 1rem;
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
	}

	&:hover {
		.header__menu {
			display: block;
		}
	}
`;
const SideNavigator: FC<{ headerHeight: number; mobileMode: boolean }> = ({ headerHeight }) => {
	const { system } = useUserSettingsStore();

	return (
		<StyledSideNavigator headerHeight={headerHeight} expanded={system.sideMenuExpanded}>
			<nav>
				<NavLink to="/" className="nav">
					<span className="nav__title">Home</span>
					<span className="nav__icon">
						<FontAwesomeIcon icon={faHome} />
					</span>
				</NavLink>
				<NavLink to="/surveys" className="nav">
					<span className="nav__title">Surveys</span>
					<span className="nav__icon">
						<FontAwesomeIcon icon={faPollH} />
					</span>
				</NavLink>
				<NavLink to="/volumes" className="nav">
					<span className="nav__title">Volumes</span>
					<span className="nav__icon">
						<FontAwesomeIcon icon={faChartBar} />
					</span>
				</NavLink>
			</nav>
			<button onClick={() => system.changeSideMenuExpanded()}>
				<span className="nav__title">{system.sideMenuExpanded ? 'Collapse' : 'Expand'}</span>
				<span className="nav__icon nav__expand-collapse">
					<FontAwesomeIcon icon={faArrowRight} />
				</span>
			</button>
		</StyledSideNavigator>
	);
};
const StyledSideNavigator = styled.div<{ headerHeight: number; expanded: boolean }>`
	position: absolute;
	left: 0;
	width: ${({ expanded }) => (expanded ? '160px' : '60px')};
	height: calc(100vh - ${({ headerHeight }) => headerHeight}px);
	background-color: ${lighten(0.05, navigationBackgroundColor)};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 100;
	overflow: hidden;
	transition: width 100ms ease-in-out;

	@media only screen and (max-width: 1232px) {
		display: ${({ expanded }) => (expanded ? 'flex' : 'none')};
	}

	.nav__title {
		display: ${({ expanded }) => (expanded ? 'block' : 'none')};
		padding: 0 1rem;
		font-size: 1rem;
	}

	&:hover {
		width: 160px;
		.nav__title {
			display: block;
		}
	}

	.nav__expand-collapse {
		svg {
			transform: rotate(${({ expanded }) => (expanded ? 180 : 0)}deg);
			transition: transform 200ms ease-in-out;
		}
	}

	button,
	.nav__icon {
		min-width: 60px;
		min-height: 50px;
		font-size: 1.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.nav {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&:hover {
			background-color: ${({ theme }) => transparentize(0.5, theme.colors.porter.primary)} !important;
		}
	}

	nav {
		display: flex;
		flex-direction: column;
	}
`;
type NavLinkProps = React.PropsWithoutRef<LinkProps<{}>> & React.RefAttributes<HTMLAnchorElement>;
const NavLink = (props: NavLinkProps) => {
	const { theme } = useTheme();

	return (
		<Link
			{...props}
			getProps={({ isCurrent }) => {
				return {
					style: {
						backgroundColor: (isCurrent && theme.colors.porter.primary) || 'inherit',
					},
				};
			}}
		/>
	);
};
