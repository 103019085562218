import { useState, useEffect } from 'react';
import storage from './storage';

export const FAVORITE_SURVEYS_KEY = '@PorterbuddyStore:FAVORITE_SURVEYS';

export const useFavorites = () => {
	const [favoriteSurveys, setFavoriteSurveys] = useState<number[]>(
		storage.getData<number[]>(FAVORITE_SURVEYS_KEY, []) || []
	);

	useEffect(() => {}, [favoriteSurveys]);

	const addOrRemoveSurveyToFavorites = (surveyId: number) => {
		const favoriteSurveyIndex = favoriteSurveys.findIndex(id => id === surveyId);
		const favoriteSurveysCopy = [...favoriteSurveys];

		if (favoriteSurveyIndex === -1) {
			favoriteSurveysCopy.push(surveyId);
		} else {
			favoriteSurveysCopy.splice(favoriteSurveyIndex, 1);
		}

		storage.storeData(FAVORITE_SURVEYS_KEY, favoriteSurveysCopy);
		setFavoriteSurveys(favoriteSurveysCopy);
	};

	return {
		favoriteSurveys,
		addOrRemoveSurveyToFavorites,
	};
};
