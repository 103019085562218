import { CSSProperties, FC } from 'react';
import styled from 'styled-components';

type Option = {
	value: string | number;
	name: string;
};

export const Select: FC<{
	value: string;
	label: string;
	onChange: (value: string) => void;
	options: Option[];
	size?: 's' | 'm' | 'l';
	style?: CSSProperties;
	disabled?: boolean;
	labelOnlyForScreenReaders?: boolean;
}> = ({ value, onChange, options, size, label, style, disabled, labelOnlyForScreenReaders }) => (
	<StyledSelect size={size} style={style}>
		<span className={labelOnlyForScreenReaders ? 'screenReaderOnly' : undefined}>{label}</span>
		<select value={value} onChange={event => onChange(event.target.value)} disabled={disabled}>
			{options.map(option => (
				<option key={option.value} value={option.value}>
					{option.name}
				</option>
			))}
		</select>
	</StyledSelect>
);

const StyledSelect = styled.label<{ size?: 's' | 'm' | 'l' }>`
	font-size: ${({ size }) => (size === 's' && '14px') || (size === 'l' && '22px') || '18px'};
	display: flex;
	flex-direction: column;
	margin-bottom: ${({ size }) => (size === 's' && '0px') || (size === 'l' && '10px') || '8px'};

	select {
		border-radius: ${({ size }) => (size === 's' && '2px') || (size === 'l' && '6px') || '4px'};
		border: 1px solid ${props => props.theme.colors.border};
		padding: ${({ size }) => (size === 's' && '4px') || (size === 'l' && '10px') || '8px'};
		background-color: transparent;
		color: ${props => props.theme.colors.text.primary};
	}
`;
