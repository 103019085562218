import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { ErrorAlert } from '../../../components/Alerts';
import { DangerOutlinedButton, OutlinedButton } from '../../../components/Buttons';
import { Row } from '../../../components/Flex';
import { Checkbox } from '../../../components/inputs/Checkbox';
import { Input } from '../../../components/inputs/Input';
import { Select } from '../../../components/inputs/Select';
import { Textarea } from '../../../components/inputs/Textarea';
import { Question, QuestionType, useCreateSurvey } from '../../../network/useSurvey';
import { EditAlternative } from './EditAlternative';
import { useEditSurveyStore } from './editSurveyContext';

export const EditQuestion: FC<{ surveyId: number; question: Question; refetch: () => void }> = ({
	surveyId,
	question: initialQuestion,
	refetch,
}) => {
	const { deleteQuestion, editQuestion, addAlternative } = useCreateSurvey();
	const { setIsSaving } = useEditSurveyStore();
	const [question, setQuestion] = useState<Question>(initialQuestion);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | undefined>(undefined);

	const handleEditQuestion = (key: keyof typeof initialQuestion, value: string | boolean) => {
		setQuestion(cs => ({
			...cs,
			[key]: value,
		}));
	};

	const handleEditQuestionMetaData = (
		key: keyof typeof initialQuestion.meta_data,
		value: string | boolean | number | Record<string, unknown>
	) => {
		setQuestion(cs => ({
			...cs,
			meta_data: {
				...cs.meta_data,
				[key]: value,
			},
		}));
	};

	const handleDeleteQuestion = async () => {
		setIsSaving(true);
		if (window.confirm(`Are you sure you want to delete ${question.question}?`)) {
			await deleteQuestion(surveyId, question.id);
			refetch();
		}
		setIsSaving(false);
	};

	const handleUpdateQuestion = async (body?: Record<string, unknown>) => {
		setIsSaving(true);
		await editQuestion(surveyId, question.id, {
			question: question.question,
			description: question.description,
			type: question.type,
			meta_data: question.meta_data,
			...body,
		});
		setIsSaving(false);
	};

	const handleAddAlternativeClick = async () => {
		setError(undefined);
		setIsLoading(true);
		setIsSaving(true);
		const response = await addAlternative(surveyId, question.id, {
			alternative: question.type === 'FREE_TEXT' ? 'Free text' : '',
			meta_data: question.type === 'FREE_TEXT' ? { multiline: true } : undefined,
		});

		if (response.success) {
			setQuestion(cs => ({
				...cs,
				alternatives: [...question.alternatives, response.data],
			}));
		} else {
			setError(response.error);
		}
		setIsLoading(false);
		setIsSaving(false);
	};

	return (
		<StyledEditQuestion>
			<Row align="flex-end" style={{ marginBottom: 16 }}>
				<Input
					label="Sort order"
					value={question.meta_data.sort_order ?? ''}
					placeholder="Order"
					onChange={text => handleEditQuestionMetaData('sort_order', parseInt(text))}
					onBlur={async () => {
						await handleUpdateQuestion();
						refetch();
					}}
					type="number"
					style={{ maxWidth: 90, marginRight: 8, marginBottom: 0 }}
				/>
				<Input
					label="ID"
					value={question.id}
					onChange={() => undefined}
					style={{ maxWidth: 60, marginRight: 8, marginBottom: 0 }}
					disabled
				/>
				<Input
					style={{ width: '100%', marginRight: 16, marginBottom: 0 }}
					label="Question"
					placeholder="Question"
					value={question.question}
					onChange={text => handleEditQuestion('question', text)}
					onBlur={handleUpdateQuestion}
				/>
				<DangerOutlinedButton onClick={handleDeleteQuestion}>
					<FontAwesomeIcon icon={faTrash} />
					Delete
				</DangerOutlinedButton>
			</Row>
			<Textarea
				value={question.description}
				onBlur={handleUpdateQuestion}
				onChange={text => handleEditQuestion('description', text)}
				label="Description"
				labelOnlyForScreenReaders
				placeholder="Description (Optional)"
			/>
			<Row align="center" style={{ marginTop: 16, marginBottom: 16 }}>
				<Select
					disabled={question.alternatives.length > 0}
					style={{ marginRight: 16, marginBottom: 0 }}
					labelOnlyForScreenReaders
					label="Question type"
					value={question.type}
					onChange={value => {
						handleEditQuestion('type', value);
						handleUpdateQuestion({
							type: value,
						});
					}}
					options={[
						{
							value: 'CHECKBOX',
							name: 'Checkbox',
						},
						{
							value: 'RADIO',
							name: 'Radio',
						},
						{
							value: 'LIKERT_SCALE',
							name: 'Likert scale',
						},
						{
							value: 'FREE_TEXT',
							name: 'Free text',
						},
					]}
				/>
				<Checkbox
					title="Required"
					checked={Boolean(question.meta_data.required)}
					style={{ marginBottom: 0 }}
					onChange={() => {
						const newValue = !Boolean(question.meta_data.required);

						handleEditQuestionMetaData('required', newValue);
						handleUpdateQuestion({
							meta_data: {
								...question.meta_data,
								required: newValue,
							},
						});
					}}
				/>
				<Row
					style={{
						whiteSpace: 'nowrap',
						marginLeft: 16,
						marginRight: 16,
						opacity: question.meta_data.required ? 0.6 : 1,
						cursor: question.meta_data.required ? 'no-drop' : 'normal',
					}}
					align="center"
				>
					Show only if alternative ID&nbsp;
					<Input
						label="Question ID"
						value={question.meta_data?.show_only_if || ''}
						onBlur={handleUpdateQuestion}
						onChange={text => handleEditQuestionMetaData('show_only_if', parseInt(text, 10))}
						labelOnlyForScreenReaders
						style={{ maxWidth: 50, marginBottom: 0 }}
					/>
					&nbsp;is selected
				</Row>
			</Row>
			{question.alternatives.map((alternative, i) => (
				<EditAlternative
					key={alternative.id + i}
					type={question.type}
					alternative={alternative}
					questionId={question.id}
					surveyId={surveyId}
					refetch={refetch}
				/>
			))}
			{showAddAlternativeButton(question.type, question.alternatives.length) && (
				<OutlinedButton onClick={handleAddAlternativeClick}>
					<FontAwesomeIcon icon={faPlus} spin={isLoading} />
					Add {question.alternatives.length > 0 && 'another'} alternative
				</OutlinedButton>
			)}
			{error && <ErrorAlert style={{ marginTop: 16 }}>{error}</ErrorAlert>}
		</StyledEditQuestion>
	);
};

const StyledEditQuestion = styled.div`
	margin-bottom: 48px;
	padding: 16px;

	&:hover {
		background-color: ${props => transparentize(0.4, props.theme.colors.background.primary)};
	}
`;

function showAddAlternativeButton(questionType: QuestionType, numberOfAlternatives: number) {
	switch (questionType) {
		case 'FREE_TEXT':
			return numberOfAlternatives < 1;
		case 'CHECKBOX':
			return true;
		case 'RADIO':
			return true;
		case 'LIKERT_SCALE':
			return numberOfAlternatives < 5;
	}
}
